
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import React, { useEffect, useState } from 'react'
import {GetserviceRequest } from '../../redux/reducer/FetchDataReducer'
import { useSelector, useDispatch } from 'react-redux'
import Loader from '../../utils/Loader'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';


const OurServices = () => {


  const fetchReducer = useSelector((state) => state.FetchDataReducer)

  var services = fetchReducer?.GetserviceResponse?.data?.banners

  console.log("service", fetchReducer?.GetserviceResponse )

  const dispatch = useDispatch()
  let navigate = useNavigate()



 

  useEffect(()=>{
    dispatch(GetserviceRequest())

},[])



    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
      };


  return (

    <section className="service-sec">
    <div className="container">
        <div className="hdr-sec">
          <h6>OUR SERVICE</h6>
          <h3>What We Provide for Best Our Service</h3>
        </div>
        {services&&services.length > 0 ?

        <Slider {...settings}>

          {services&&services.map((item, i) =>{
            return (
              <div className="item" key={i}>
                <div className="service-box">
                  <div className="service-img">
                    <img src={fetchReducer?.GetserviceResponse?.data?.baseUrl + item?.image} alt="" />
                    <span><img src={fetchReducer?.GetserviceResponse?.data?.baseUrl + item?.icon} alt="" /></span>
                  </div>
                  <h6>{item?.heading}</h6>
                  <p>{item?.description}</p>
                  {/* <a href="#" className="d-btn">Details</a> */}
                </div>
            </div>
            )
          })}

          </Slider>
          :

          <h5>No services</h5>
        }
      </div>

  </section>

  )
}

export default OurServices