import React from 'react'
import { IMAGE } from '../../utils/Theme'

const WorkProcess = () => {
  return (
    <section className="service-work-sec">
  <div className="container">
  <div className="hdr-sec">
        <h6>HOW OUR SERVICE WORKS</h6>
        <h3>Clean Clothes Have Never Been This Easy!</h3>
      </div>
    <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-12">
          <div className="circle-s-box">
            <div className="circle-s-img">
              <img src={IMAGE.login_img} alt="" />
            </div>
            <h6>Sign Up</h6>
            </div>
        </div>

        <div className="col-lg-3 col-md-3 col-sm-12">
          <div className="circle-s-box">
            <div className="circle-s-img">
            <img src={IMAGE.pickup_img} alt="" />
            </div>
            <h6>Pick Up</h6>
            </div>
        </div>

        <div className="col-lg-3 col-md-3 col-sm-12">
          <div className="circle-s-box">
            <div className="circle-s-img">
              <img src={IMAGE.cleaning} alt="" />
            </div>
            <h6>Cleaning</h6>
            </div>
        </div>

        <div className="col-lg-3 col-md-3 col-sm-12">
          <div className="circle-s-box no-bfr">
            <div className="circle-s-img">
              <img src={IMAGE.Delivery} alt="" />
            </div>
            <h6>Delivery</h6>
            </div>
        </div>

     
    </div>
  </div>
</section>
  )
}

export default WorkProcess