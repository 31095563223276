import React from 'react'
import { IMAGE } from '../../utils/Theme'
import { NavLink } from 'react-router-dom';

const Footer = () => {

  var token = localStorage.getItem('access_token');

  return (
    <>
    <footer className="footer">
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-6 col-12 left-footer">
          <div className="footer-logo-area">
            <img className="black-logo" src={IMAGE.logo} />
          </div>

          <p>Shine Silver Automatic Laundry provides premium laundry service to customers through free pick up and delivery in Abu Dhabi.</p>
          {/* <div className="footer-about">
            <ul>
              <li><a href="#"><i className="fa-brands fa-facebook-f"></i></a></li>
              <li><a href="#"><i className="fa-brands fa-twitter"></i></a></li>
              <li><a href="#"><i className="fa-brands fa-instagram"></i></a></li>
              <li><a href="#"><i className="fa-brands fa-youtube"></i></a></li>
            </ul>
          </div> */}
        </div>


        <div className="col-lg-5 col-md-6 col-12">
          <div className="footer-links footer-quick-links">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <h3>Quick Links</h3>
                <ul>
                  
                  <li>  <NavLink to="/"
                className={({ isActive }) => (isActive ? "active" : undefined)}
                >Home</NavLink></li>
               <li>
                <NavLink to="/about"
                className={({ isActive }) => (isActive ? "active" : undefined)}
                >About</NavLink>
            </li>
            {token ?  <li>
                <NavLink to="/dashboard" className={({ isActive }) => (isActive ? "active" : undefined)}>Create Order</NavLink>
            </li> :
             <li>
             <NavLink to="/login" className={({ isActive }) => (isActive ? "active" : undefined)}>Create Order</NavLink>
         </li>
            }   
          
                </ul>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <h3></h3>
                <ul>
                <li>
                <NavLink to="/process"
                className={({ isActive }) => (isActive ? "active" : undefined)}
                >Process</NavLink>
            </li>
            <li>
                <NavLink to="/package" className={({ isActive }) => (isActive ? "active" : undefined)}>Package</NavLink>
            </li>
            <li>
                <NavLink to="/support"
                className={({ isActive }) => (isActive ? "active" : undefined)}
                >Help & Support</NavLink>
            </li>
            <li>
                <NavLink to="/faq"
                className={({ isActive }) => (isActive ? "active" : undefined)}
                >FAQ</NavLink>
            </li>

                </ul>
              </div>
            </div>

          </div>
        </div>

        {/* <div className="col-lg-3 col-md-6 col-12">
          <div className="footer-links footer-quick-links">
            <h3>Newsletter</h3>
            <p>Shine Silver Automatic Laundry provides premium laundry service to customers through free pick up and delivery in Abu Dhabi.</p>
            <div className="subscr">
              <div className="input-group mb-4 mt-4">
                <input type="text" className="form-control" placeholder="Enter email address"
                  aria-describedby="button-addon2" />
                <button className="btn" type="button" id="button-addon2" data-mdb-ripple-color="dark">
                  <img src={IMAGE.sendIcon} alt="" />
                </button>
              </div>
            </div>
            <ul className="footer-bottom-link">
              <li><a href="tel:025599629"><i className="fa-solid fa-phone"></i> 02 5599629</a></li>
              <li><a href="tel:0521794598"><i className="fa-brands fa-whatsapp"></i> 052 1794598</a></li>
            </ul>
          </div>
        </div> */}

      </div>
    </div>
  </footer>
   <div className="copyright">
   <div className="container">
     <div className="row">
       <div className="col-lg-6 col-md-6 col-12 "><a href="#">Copyright © {new Date().getFullYear()} shinesilverlaundry.</a>All Rights Reserved.</div>

       {/* <div className="col-lg-6 col-md-6 col-12 rgt-alg">
         <a href="#">Privacy Policy</a>
         <a href="#">Terms of Service</a>
       </div> */}
     </div>
   </div>
 </div>
 </>
  )
}

export default Footer