import React, { useEffect, useState } from "react";
import {
    GetsupportRequest,
} from "../../redux/reducer/FetchDataReducer";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../utils/Loader";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const HelpSupport = () => {

    const fetchReducer = useSelector((state) => state.FetchDataReducer);

    const dispatch = useDispatch();
    let navigate = useNavigate();
  
    console.log(fetchReducer.GetsupportResponse)
  
  useEffect(()=>{
      dispatch(GetsupportRequest())
      window.scrollTo(0,0)
  },[])

  



  return (
    <section className="faq-sec">
        <Loader visible={fetchReducer.isloading} />
    <div className="container">
     
            <h3>Helps and Support</h3>
            {fetchReducer?.GetsupportResponse?.helpAndSupports
&&fetchReducer?.GetsupportResponse?.helpAndSupports
.map((item, index)=>{
        return (
            <div className="mb-5" key={index}>
                <h5 className="mb-3"><b>{item?.question}</b> </h5>
                <p>{item?.answer}</p>
            </div>
        )
    })}  

          
        </div>
   
</section>
  )
}

export default HelpSupport