import React, { useEffect, useState } from 'react'
import {DashboardBannerRequest, PaymentRequest} from '../../redux/reducer/FetchDataReducer'
import { useSelector, useDispatch } from 'react-redux'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useNavigate } from 'react-router-dom';

const DashboardBanner = () => {
    const fetchReducer = useSelector((state) => state.FetchDataReducer)

    var bannerItem = fetchReducer?.DashboardBannerListResponse?.data?.packages

    const dispatch = useDispatch()
    let navigate = useNavigate()

    useEffect(()=>{
        dispatch(DashboardBannerRequest())
    },[])

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
      };

      const CreatePayment = (amount) => {

        let obj = {
          'amount': amount,
        }
  
        dispatch(PaymentRequest({obj: obj,navigate:navigate}))
  
      }

  return (
    <section className="plan-big-sec">
    <div className="container">
    <Slider {...settings}>
        {bannerItem&&bannerItem.map((item, index)=>{
            return (
                <div className='item' key={index}>
                <div className="row align-items-center">
                    <div className="col-lg-3 bx1">
                        <h6>Recomended</h6>
                        <h3>{item?.packageName}</h3>
                    </div>
                    <div className="col-lg-6 bx2">
                        <p><i className="fa-solid fa-chevron-right"></i> {item?.packageDesc}</p>
                    </div>
                    <div className="col-lg-3 bx3">
                        <h3>AED {item?.packageAmt?.$numberDecimal}</h3>
                        <button className="go-pro" onClick={()=>CreatePayment(item?.packageAmt?.$numberDecimal)}>GO PRO</button>
                    </div>
                </div>
                </div>
            )
        })}
     
        
        </Slider>
    </div>
</section>
  )
}

export default DashboardBanner