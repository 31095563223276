import React, { useEffect, useState } from 'react'
import {DashboardBannerRequest, PaymentRequest} from '../../redux/reducer/FetchDataReducer'
import { useSelector, useDispatch } from 'react-redux'
import Loader from "../../utils/Loader";
import { useNavigate } from 'react-router-dom';

const PackageList = () => {
    const fetchReducer = useSelector((state) => state.FetchDataReducer)

    var bannerItem = fetchReducer?.DashboardBannerListResponse?.data?.packages

    const dispatch = useDispatch()
    let navigate = useNavigate()

    useEffect(()=>{
        dispatch(DashboardBannerRequest())
        window.scrollTo(0,0)
    },[])
    
    const CreatePayment = (amount) => {

      let obj = {
        'amount': amount,
      }

      dispatch(PaymentRequest({obj: obj,navigate:navigate}))

    }





  return (
   
    <section className="custom-packg-sec">
        <Loader visible={fetchReducer.isloading} />
        <div className="container">
        <div className="pck-btn">
                <h3>Packages</h3>
               {/* <a href="#" className="crt-pckg">CREATE PACKAGE</a> */}
            </div>
            <div className="row">
            {bannerItem&&bannerItem.map((item, index)=>{
            return (
               <div className="col-lg-6 "  key={index}>
                <div className="pckg-box-big">
             <h6>Most Purchased</h6>
             <h3>{item?.packageName}</h3>
             <div className="inner-box">
                <ul>
                    <li><i className="fa-solid fa-chevron-right"></i> {item?.packageDesc}</li>
                   </ul>
                    <div className="right-inn-bx">
                        <h4>AED {item?.packageAmt?.$numberDecimal}</h4>
                        <button className="go-pro" onClick={()=>CreatePayment(item?.packageAmt?.$numberDecimal)}>GO PRO</button>
                    </div>
             </div>
               </div>
            </div>
            )
            })}
            

            </div>
        </div>
    </section>
  )
}

export default PackageList