import React, { useEffect } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { IMAGE } from '../../utils/Theme'
import { ConfirmPaymentRequest } from '../../redux/reducer/FetchDataReducer'
import { useDispatch } from 'react-redux'

const PaymentSuccess = () => {
    const {id} = useParams()
    const dispatch = useDispatch()

    useEffect(()=>{

        const FinalSuccess = async () => {
            let obj = {
                'stripeOrderId': id,
              }
        
              dispatch(ConfirmPaymentRequest({obj: obj}))
}
FinalSuccess()
    },[])



  return (
    <div className='success-sec'>
    <div className='success-box'>
        <img src={IMAGE.succes_image} />
        <h5 className='my-3'>Thanks for purchase</h5>
        <NavLink to="/dashboard" className="btn btn-primary">Return home</NavLink>
    </div>

</div>
  )
}

export default PaymentSuccess