import { configureStore } from '@reduxjs/toolkit';
import fetchReducer from './reducer/FetchDataReducer';
import createSagaMiddleware from 'redux-saga';
import RootSaga from './saga/RootSaga';
import Productdatareducer from './reducer/ProductReducer';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'

let sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({ 
  FetchDataReducer: fetchReducer,
  Productdatareducer: Productdatareducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const Store = configureStore({
  reducer: persistedReducer,
  middleware
})

export const persistor = persistStore(Store)


sagaMiddleware.run(RootSaga)