import React, { useEffect, useState } from "react";
import {
    OrderDetailsRequest,
    GetInvoiceRequest,
    SavereviewRequest,
    GetReviewRequest
} from "../../redux/reducer/FetchDataReducer";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../utils/Loader";
import moment from "moment";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';

const OrderDetails = () => {
  const [show, setShow] = useState(false);
  const [rating, setRating] = useState("3")
  const [comment, setcomment] = useState("")

    const fetchReducer = useSelector((state) => state.FetchDataReducer);

    var OrderDetailsdata = fetchReducer?.OrderDetailsResponse?.data?.orderDetails
    var Itemdata = fetchReducer?.OrderDetailsResponse?.data?.orderItems
    var ReviewList = fetchReducer?.GetReviewResponse?.data?.reviews


    const dispatch = useDispatch();
    let navigate = useNavigate();
    let {id} = useParams()

    useEffect(()=>{
        let obj ={
            'orderId':id
        }
        dispatch(OrderDetailsRequest(obj))

     
  
    },[])


    const InvoiceHandle = ()=>{
      let obj = {
        orderId:OrderDetailsdata?._id
      }
      dispatch(GetInvoiceRequest(obj))
    }

    const handleClose = () => setShow(false);

    const handleShow = () => {
      setShow(true)

    };

    const SaveReview = () => {
        let obj = {
          "deliveryBoyId": OrderDetailsdata?.deliveryBoyId,
          "comment": comment,
          "star": rating
      }

      dispatch(SavereviewRequest(obj))
      setShow(false)
    }

    useEffect(()=>{

      if(OrderDetailsdata?.currentStatus == 'completed'){
        
      let obj1 ={
        'deliveryBoyId':OrderDetailsdata?.deliveryBoyId
    }
    dispatch(GetReviewRequest(obj1))
      }

    },[fetchReducer?.OrderDetailsResponse])

    useEffect(()=>{
      if(OrderDetailsdata?.currentStatus == 'completed'){
      let obj1 ={
        'deliveryBoyId':OrderDetailsdata?.deliveryBoyId
    }
    dispatch(GetReviewRequest(obj1))
  }
    },[fetchReducer?.SavereviewResponse])


  return (
    <>
    <Loader visible={fetchReducer.isloading} />
    
    <section className="dash-pills">
        <div className="container">
          <div className="row">
          <div className="col-lg-7 track-sec">
          <h3>Order Details</h3>
                    <h5><b>Order Amount : AED {OrderDetailsdata?.paidAmount?.$numberDecimal}</b></h5>

                    <div className="date-sec">

                        <div className="pickup-date">
                           
                            <div className="mb-3">
                                <p>Pick Up Date </p>
                                <span>{ moment(OrderDetailsdata?.slotBookingId?.pickUpDate).format("DD-MM-YYYY")}</span>
                            </div>
                            <div className="mb-3">
                                <p>Pick Up Time </p>
                                <span>{OrderDetailsdata?.slotBookingId?.pickUpTime}</span>
                            </div>
                        </div>
                        <div className="delivery-date">
                            <div className="mb-3">
                                <p> Delivery Date </p>
                                <span>{ moment(OrderDetailsdata?.slotBookingId?.deliveryDate).format("DD-MM-YYYY")}</span>
                            </div>
                            <div className="mb-3">
                                <p>Delivery Time </p>
                                <span>{OrderDetailsdata?.slotBookingId?.deliveryTime}</span>
                            </div>
                        </div>

                    </div>
                    {ReviewList&&ReviewList.length > 0 ?
                    <div className="reviewList">
                        {ReviewList&&ReviewList.map((list, i)=>{
                          var stardata = []

                          for (var i = 0; i < 5; i++){
                            if(i < list.star){
                              stardata.push(<i className="fa fa-star checked"></i>)
                            } else {
                              stardata.push(<i className="fa fa-star"></i>)
                            }
                          }

                          return (
                            <div className="reviewList-box" key={i}>
                               {stardata}
                                <p>{list?.comment}</p>
                           </div>
                          )
                        })}
                      
               
                    </div>
                    :
                    <h6>No Review Found</h6>

}

{OrderDetailsdata?.currentStatus == 'completed' && 

                    <ul className="d-flex">
                      <li>
                        <button className="btn btn-primary" onClick={handleShow}>Add Review</button>
                      </li>
                     
                    </ul>
}

                    <a id="Download" download target="_blank"></a>
                </div>
                <div className="col-lg-5">
                <div className="rvew-right p-0">
                  <div className="table-responsive">
                      <h4>Your Order Summary</h4>
                      <table className="table">
                          <thead>
                              <tr>
                                  <th >Item</th>
                                  <th >Qty</th>
                                  <th >Last</th>

                              </tr>
                          </thead>
                          
                          <tbody>
                            {Itemdata&&Itemdata.map((item, index)=>{
                              return (
                                <tr key={index}>
                                <td>{item?.name}</td>
                                <td>{item?.qty}</td>
                            <td>
                          

                          <span>AED {item?.total?.$numberDecimal} </span>
                          </td>
                          </tr>
                              )
                            })}
                            <tr>
                              <td colSpan="3" align="right">
                                  <b>Total : AED {OrderDetailsdata?.paidAmount?.$numberDecimal}</b>
                              </td>
                            </tr>
                      
                          </tbody>

                      </table>

                  </div>
                  <button className="btn btn-warning ml-auto" onClick={InvoiceHandle}>Download Invoice</button>
              </div>
                </div>
          </div>
           
        </div>
        <Modal show={show} onHide={handleClose}  size="md">
        <Modal.Header >
          <Modal.Title>Give Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div id="full-stars-example">

        <div className="rating-group">
        <label aria-label="1 star" className="rating__label" htmlFor="rating-1"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
        <input className="rating__input" name="rating" id="rating-1" value="1" type="radio"  onChange={(e)=>setRating(e.target.value)}  />
        <label aria-label="2 stars" className="rating__label" htmlFor="rating-2"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
        <input className="rating__input" name="rating" id="rating-2" value="2" type="radio" onChange={(e)=>setRating(e.target.value)}  />
        <label aria-label="3 stars" className="rating__label" htmlFor="rating-3"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
        <input className="rating__input" name="rating" id="rating-3" value="3" type="radio" onChange={(e)=>setRating(e.target.value)} checked  />
        <label aria-label="4 stars" className="rating__label" htmlFor="rating-4"><i className="rating__icon rating__icon--star fa fa-star"></i></label>
        <input className="rating__input" name="rating" id="rating-4" value="4" type="radio" onChange={(e)=>setRating(e.target.value)}  />
        <label aria-label="5 stars" className="rating__label" htmlFor="rating-5" ><i className="rating__icon rating__icon--star fa fa-star"></i></label>
        <input className="rating__input" name="rating" id="rating-5" value="5" type="radio" onChange={(e)=>setRating(e.target.value)}  />
    </div>
       
       

      </div>
      <div className="form-group"> 
          <textarea className="form-control" placeholder="Comment Here" value={comment} onChange={(e)=>setcomment(e.target.value)}>

          </textarea>
      </div>
          
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-warning' onClick={handleClose}>
            Close
          </button>
          <button className='btn btn-primary' onClick={SaveReview}>
           Submit Review
          </button>
        </Modal.Footer>
      </Modal>
    </section>
    </>
  )
}

export default OrderDetails