import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

const DashboardOrderSummery = () => {

    const fetchproductReducer = useSelector((state) => state.Productdatareducer)
   




  return (
    <div className="rvew-right p-0">
    <div className="table-responsive">
        <h4>Your Order Summary</h4>
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">Item</th>
                    <th scope="col">Qty</th>
                    <th scope="col">Last</th>

                </tr>
            </thead>
            
            <tbody>
                
                <tr>
                    <td><i className="fa-solid fa-check"></i> {fetchproductReducer?.ChooseproductList&&fetchproductReducer?.ChooseproductList[0]?.service_list[0]
?.service_name} </td>
                    <td>{fetchproductReducer?.serviceDry?.qty}</td>
                    <td>AED {fetchproductReducer?.serviceDry?.subtotal}</td>
                </tr>

                <tr>
                    <td><i className="fa-solid fa-check"></i> {fetchproductReducer?.ChooseproductList&&fetchproductReducer?.ChooseproductList[0]?.service_list[1]
?.service_name}  </td>
                     <td>{fetchproductReducer?.serviceWash?.qty}</td>
                    <td>AED {fetchproductReducer?.serviceWash?.subtotal}</td>
                </tr>

                <tr>
                    <td><i className="fa-solid fa-check"></i> {fetchproductReducer?.ChooseproductList&&fetchproductReducer?.ChooseproductList[0]?.service_list[2]
?.service_name}</td>
                      <td>{fetchproductReducer?.serviceIron?.qty}</td>
                    <td>AED {fetchproductReducer?.serviceIron?.subtotal}</td>
                </tr>
                <tr className="grand-totl">
                    <td colspan="3">
                        Grand Total : <span> AED {fetchproductReducer?.serviceDry?.subtotal + fetchproductReducer?.serviceWash?.subtotal + fetchproductReducer?.serviceIron?.subtotal}</span>
                    </td>

                </tr>
            </tbody>

        </table>

     
    </div>
</div>
  )
}

export default DashboardOrderSummery