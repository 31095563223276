import {takeEvery,takeLatest, call, put, select } from 'redux-saga/effects'
import { toast } from 'react-toastify';
import { getApi, postApi, getApiWithParam } from '../../utils/ApiRequest';


import {
  ZoneListSuccess,
  ZoneListFailure,
  SignupSuccess,
  SignupFailure,
  LoginSuccess,
  LoginFailure,
  ResetOTPSuccess,
  ResetOTPFailure,
  ValidateotpSuccess,
  ValidateotpFailure,
  ResendOTPSuccess,
  ResendOTPFailure,
  CategoryListSuccess,
  CategoryListFailure,
  productListSuccess,
  productListFailure,
  DashboardBannerSuccess,
  DashboardBannerFailure,
  GetSlotListSuccess,
  GetSlotListFailure,
  GetTimeSlotSuccess,
  GetTimeSlotFailure,
  GetdeliveryTimeSlotSuccess,
  GetdeliveryTimeSlotFailure,
  SlotbookSuccess,
  SlotbookFailure,
  SlotDetailsSuccess,
  SlotDetailsFailure,
  GetCouponSuccess,
  GetCouponFailure,
  SlotpaymentSuccess,
  SlotpaymentFailure,
  GetOrderHistorySuccess,
  GetOrderHistoryFailure,
  OrderDetailsSuccess,
  OrderDetailsFailure,
  GetInvoiceFailure,
  SavereviewSuccess,
  SavereviewFailure,
  GetReviewSuccess,
  GetReviewFailure,
  GetInvoiceSuccess,
  GetbannerSuccess,
  GetbannerFailure,
  GetserviceSuccess,
  GetserviceFailure,
  GetFaqSuccess,
  GetFaqFailure,
  GetsupportSuccess,
  GetsupportFailure,
  GetWalletSuccess,
  GetWalletFailure,
  GetProfileSuccess,
  GetProfileFailure,
  GetProcessSuccess,
  GetProcessFailure,
  PaymentSuccess,
  PaymentFailure,
  ConfirmPaymentSuccess,
  ConfirmPaymentFailure,

  GetaboutSuccess,
  GetaboutFailure,

  UpdateProfileSuccess,
  UpdateProfileFailure,

  ForgotpasswordSuccess,
  ForgotpasswordFailure,

  setpasswordSuccess,
  setpasswordFailure,

  CreatePaymentSuccess,
  CreatePaymentFailure,

  OrderConfirmPaymentSuccess,
  OrderConfirmPaymentFailure

} from '../reducer/FetchDataReducer';


var token = localStorage.getItem('access_token');

function* ZoneRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
    };

    try {
      let response = yield call(getApi, `customer/area-list`, header);
      if(response.data.status){
        yield put(ZoneListSuccess(response.data))
      } else {
        yield put(ZoneListFailure(response))
      }
    } catch(err){
     
      yield put(ZoneListFailure(err.data))
     
  }
}

function* SignupRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
    };

    try {
      let response = yield call(postApi, 'sign-up', action.payload.obj, header);
      if(response.data.status){
        yield put(SignupSuccess(response.data))
        toast.success(response.data?.message);
        action.payload.navigate("/login")
       
      } else {
        toast.error(response.data?.message);
        yield put(SignupFailure(response))
      }
    } catch(err){
      yield put(SignupFailure(err.data))
      console.log("saga",err)
    }
}

// function* LoginRequest(action) {

//   let header = {
//       Accept: 'application/json',
//       contenttype: 'application/json',
//     };

 

//     try {
//       let response = yield call(postApi, 'sign-in', action.payload.obj, header);
//       if(response.data.status){
//         yield put(LoginSuccess(response.data))
//         if (response?.data?.data?.accessToken != null) {
//           localStorage.setItem('access_token', response.data?.data?.accessToken)
//         }
//         toast.success(response.data?.message);

//         console.log("login response", response.data)
//         return
//         action.payload.navigate("/dashboard")
//        window.location.reload();


//       } else {
//         toast.error(response.data?.message);
//         yield put(LoginFailure(response))
//       }
//     } catch(err){
//       toast.error(err?.response.data?.message);
//       yield put(LoginFailure(err.data))
//       console.log("saga",err)
//     }
// }

function* LoginRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
    };

 

    try {
      let response = yield call(postApi, 'sign-in', action.payload.obj, header);
      if(response.data.status){
        yield put(LoginSuccess(response.data))
        //toast.success(response.data?.message);
        //action.payload.navigate("/otp", { state: { mobileno: action.payload.phoneno, otp:response.data?.data?.otp  } })
       // window.location.reload();
       let obj= {
        "phone":action.payload.phoneno.toString(),
        "otp":response?.data?.data?.otp.toString()
    }
       let responseverify = yield call(postApi, 'validate-otp', obj, header);

       if(responseverify.data.status){
        if (responseverify?.data?.data?.accessToken != null) {
          localStorage.setItem('access_token', responseverify.data?.data?.accessToken)
        }
        yield put(ValidateotpSuccess(responseverify.data))
        toast.success(responseverify.data?.message);
        action.payload.navigate("/dashboard")
       window.location.reload();
      } else {
        yield put(ValidateotpFailure(responseverify.data))
        console.log("Validateotp faild")
        toast.error(responseverify.data?.message);
      }

      
      } else {
        toast.error(response.data?.message);
        yield put(LoginFailure(response))
      }
    } catch(err){
      toast.error(err?.response.data?.message);
      yield put(LoginFailure(err.data))
      console.log("saga",err)
    }
}

function* ResetOTPRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
    };

    try {
      let response = yield call(postApi, 'expire-otp', action.payload, header);
      if(response.data.status){
        yield put(ResetOTPSuccess(response.data))
      } else {
        yield put(ResetOTPFailure(response))
      }
    } catch(err){
      yield put(ResetOTPFailure(err.data))
      console.log("saga",err)
    }
}

function* ResendOTPRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
    };

    try {
      let response = yield call(postApi, 'resend-otp', action.payload, header);
      if(response.data.status){
        toast.success(response.data?.message);
        yield put(ResendOTPSuccess(response.data))
      } else {
        yield put(ResendOTPFailure(response))
      }
    } catch(err){
      yield put(ResendOTPFailure(err.data))
      console.log("saga",err)
    }
}

function* ValidateotpRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
    };

    console.log("very Request", action.payload.obj);

    return

    try {
      let response = yield call(postApi, 'validate-otp', action.payload.obj, header);
      if(response.data.status){
        if (response?.data?.data?.accessToken != null) {
          localStorage.setItem('access_token', response.data?.data?.accessToken)
        }
        yield put(ValidateotpSuccess(response.data))
        toast.success(response.data?.message);
        action.payload.navigate("/dashboard")
       window.location.reload();
      } else {
        yield put(ValidateotpFailure(response.data))
        console.log("Validateotp faild")
        toast.error(response.data?.message);
      }
      

    } catch(err){
      yield put(ValidateotpFailure(err.data))
      console.log("Validateotp", err.response)
      toast.error(err?.response.data?.message);
    }

  
}

function* CategoryRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(getApi, 'customer/product-category-list', header);
      if(response.data.status){
        yield put(CategoryListSuccess(response.data))
      } else {
        yield put(CategoryListFailure(response))
      }
    } catch(err){
      if(err?.response?.status == 401){
        yield put(CategoryListFailure(err.data))
      action.payload.navigate("/login")
      localStorage.clear();
      window.location.reload()
    }
    }
}

function* ProductListRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(postApi, 'customer/product-list', action.payload, header);
      if(response.data.status){
        yield put(productListSuccess(response.data))
      } else {
        yield put(productListFailure(response))
      }
    } catch(err){
      yield put(productListFailure(err.data))
    }
}

function* DashboardBannerRequest() {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
    };

    try {
      let response = yield call(getApi, 'packages?isShow=true', header);
      if(response.data.status){
        yield put(DashboardBannerSuccess(response.data))
      } else {
        yield put(DashboardBannerFailure(response))
      }
    } catch(err){
      yield put(DashboardBannerFailure(err.data))
      console.log("saga",err)
    }
}

function* GetSlotListRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(postApi, 'customer/slot-items', action.payload.obj, header);
      if(response.data.status){
        yield put(GetSlotListSuccess(response.data))
        toast.success(response.data?.message);
        action.payload.navigate("/slot-booking")
      } else {
        yield put(GetSlotListFailure(response))
      }
    } catch(err){
      yield put(GetSlotListFailure(err.data))
    }
}

function* GetPickupTimeSlotRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(postApi, 'time-slots-new', action.payload, header);
      if(response.data.status){
        yield put(GetTimeSlotSuccess(response.data))
      } else {
        yield put(GetTimeSlotSuccess(response.data))
      }
    } catch(err){
      yield put(GetTimeSlotFailure(err.data))
    }
}

function* GetDeliveryTimeSlotRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(postApi, 'time-slots-delivery-new', action.payload, header);
      if(response.data.status){
        yield put(GetdeliveryTimeSlotSuccess(response.data))
      } else {
        yield put(GetdeliveryTimeSlotSuccess(response.data))
      }
    } catch(err){
      yield put(GetdeliveryTimeSlotFailure(err.data))
    }
}

function* SlotbookRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(postApi, 'customer/slot-book', action.payload.obj, header);
      if(response.data.status){
        yield put(SlotbookSuccess(response.data))
        toast.success(response.data?.message)
        action.payload.navigate("/confirm-order")
      } else {
        yield put(SlotbookSuccess(response.data))
      }
    } catch(err){
      yield put(SlotbookFailure(err.data))
    }
}


function* SlotDetailsRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(postApi, 'customer/slot-details', action.payload, header);
      if(response.data.status){
        yield put(SlotDetailsSuccess(response.data))
      } else {
        yield put(SlotDetailsSuccess(response.data))
      }
    } catch(err){
      yield put(SlotDetailsFailure(err.data))
    }
}

function* GetCouponRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(getApi, 'coupons', header);
      if(response.data.status){
        yield put(GetCouponSuccess(response.data))
      } else {
        yield put(GetCouponSuccess(response.data))
      }
    } catch(err){
      yield put(GetCouponFailure(err.data))
    }
}

function* GetOrderHistoryRequest() {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(getApi, 'customer/order-list', header);
      if(response.data.status){
        yield put(GetOrderHistorySuccess(response.data))
      } else {
        yield put(GetOrderHistorySuccess(response.data))
      }
    } catch(err){
      yield put(GetOrderHistoryFailure(err.data))
    }
}


function* SlotpaymentRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(postApi, 'customer/slot-payment', action.payload.obj, header);
      if(response.data.status){
        yield put(SlotpaymentSuccess(response.data))
        toast.success(response.data?.message);
        action.payload.navigate("/order-history")
      } else {
        toast.error(response.data?.message);
        yield put(SlotpaymentSuccess(response.data))
      }
    } catch(err){
      yield put(SlotpaymentFailure(err.data))
    }
}

function* OrderDetailsRequest(action) {

  console.log("Order Details", action.payload)

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(postApi, 'customer/assigned-slots-order-detail', action.payload, header);
      if(response.data.status){
        yield put(OrderDetailsSuccess(response.data))
      } else {
        yield put(OrderDetailsSuccess(response.data))
      }
    } catch(err){
      yield put(OrderDetailsFailure(err.data))
    }
}

function* GetInvoiceRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(getApi, `customer/generate-invoice?orderId=${action.payload.orderId}`, header);
      if(response.data.status){
        yield put(GetInvoiceSuccess(response.data))
        
        const link = document.getElementById("Download");
            link.setAttribute("href", response.data?.data?.url);
            link.click();
      } else {
        yield put(GetInvoiceFailure(response.data))
      }
    } catch(err){
      yield put(GetInvoiceFailure(err.response))
    }
}

function* SavereviewRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(postApi, 'customer/save-review', action.payload, header);
      if(response.data.status){
        yield put(SavereviewSuccess(response.data))
        toast.success(response.data?.message);
       
      } else {
        yield put(SavereviewFailure(response))
      }
    } catch(err){
      yield put(SavereviewFailure(err.data))
      console.log("saga",err)
    }
}

function* GetReviewRequest(action) {
  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(postApi, 'customer/review-list', action.payload, header);
      if(response.data.status){
        yield put(GetReviewSuccess(response.data))
      } else {
        yield put(GetReviewFailure(response.data))
      }
    } catch(err){
      yield put(GetReviewFailure(err.data))
    }
}

function* GetbannerRequest(action) {
  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
    };

    try {
      let response = yield call(getApi, 'screen-details?screenName=HOME_SCREEN&type=banner', header);
      if(response.data.status){
        yield put(GetbannerSuccess(response.data))
      } else {
        yield put(GetbannerFailure(response.data))
      }
    } catch(err){
      yield put(GetbannerFailure(err.data))
    }
}

function* GetserviceRequest(action) {
  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
    };

    try {
      let response = yield call(getApi, 'banners?screenName=HOME_SCREEN&type=banner', header);
      if(response.data.status){
        yield put(GetserviceSuccess(response.data))
      } else {
        yield put(GetserviceFailure(response.data))
      }
    } catch(err){
      yield put(GetserviceFailure(err.response))
    }
}

function* GetFaqRequest(action) {
  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
    };

    try {
      let response = yield call(getApi, 'customer/faq', header);
      if(response.data.status){
        yield put(GetFaqSuccess(response.data?.data))
      } else {
        yield put(GetFaqFailure(response.data))
      }
    } catch(err){
      yield put(GetFaqFailure(err.response))
    }
}

function* GetsupportRequest(action) {
  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
    };

    try {
      let response = yield call(getApi, 'customer/help-and-support', header);
      if(response.data.status){
        yield put(GetsupportSuccess(response.data?.data))
      } else {
        yield put(GetsupportFailure(response.data))
      }
    } catch(err){
      yield put(GetsupportFailure(err.response))
    }
}

function* GetWalletRequest(action) {
  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(getApi, 'customer/wallet-balance', header);
      if(response.data.status){
        yield put(GetWalletSuccess(response.data?.data))
      } else {
        yield put(GetWalletFailure(response.data))
      }
    } catch(err){
      yield put(GetWalletFailure(err.response))
    }
}

function* GetProfileRequest(action) {
  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(getApi, 'user-profile', header);
      if(response.data.status){
        yield put(GetProfileSuccess(response.data?.data))
      } else {
        yield put(GetProfileFailure(response.data))
      }
    } catch(err){
      yield put(GetProfileFailure(err.response))
    }
}

function* GetProcessRequest(action) {
  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
    };

    try {
      let response = yield call(getApi, 'process-management-videos?screenName=HOME_SCREEN&type=promotion', header);
      if(response.data.status){
        yield put(GetProcessSuccess(response.data?.data))
      } else {
        yield put(GetProcessFailure(response.data))
      }
    } catch(err){
      yield put(GetProcessFailure(err.response))
    }
}

function* PaymentRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(postApi, 'customer/recharge-wallet', action.payload.obj, header);
      if(response.data.status){
        yield put(PaymentSuccess(response.data))
        toast.success(response.data?.message)
        let obj = {
          clientSecret: response.data?.data?.paymentIntent
      }
        action.payload.navigate("/payment",{state:{
          response: response.data?.data,
          secret: obj
        }
      })
      } else {
        yield put(PaymentSuccess(response.data))
      }
    } catch(err){
      yield put(PaymentFailure(err.data))
    }
}

function* ConfirmPaymentRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(postApi, 'customer/recharge-verify', action.payload.obj, header);
      if(response.data.status){
        yield put(ConfirmPaymentSuccess(response.data))
        toast.success(response.data?.message)
        action.payload.navigate("/dashboard")
      } else {
        yield put(ConfirmPaymentSuccess(response.data))
      }
    } catch(err){
      yield put(ConfirmPaymentFailure(err.data))
    }
}


function* GetaboutRequest(action) {
  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
    };

    try {
      let response = yield call(getApi, 'customer/about-us ', header);
      if(response.data.status){
        yield put(GetaboutSuccess(response.data?.data))
      } else {
        yield put(GetaboutFailure(response.data))
      }
    } catch(err){
      yield put(GetaboutFailure(err.response))
    }
}


function* UpdateProfileRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(postApi, 'update-user-profile', action.payload, header);
      if(response.data.status){
        yield put(UpdateProfileSuccess(response.data))
        toast.success(response.data?.message)
      
      } else {
        yield put(UpdateProfileFailure(response))
        toast.error(response.data?.message)
      }
    } catch(err){
      yield put(UpdateProfileFailure(err.data))
    }
}

function* ForgotpasswordRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
    };

 

    try {
      let response = yield call(postApi, 'lost-password', action.payload.obj, header);
      if(response.data.status){
        yield put(ForgotpasswordSuccess(response.data))

        toast.success(response.data?.message);
        action.payload.navigate("/change-password")

      
      } else {
        toast.error(response.data?.message);
        yield put(ForgotpasswordFailure(response))
      }
    } catch(err){
      yield put(ForgotpasswordFailure(err.data))
      console.log("saga",err)
    }
}

function* setpasswordRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
    };

 

    try {
      let response = yield call(postApi, 'new-password-set', action.payload.obj, header);
      if(response.data.status){
        yield put(setpasswordSuccess(response.data))

        toast.success(response.data?.message);
        action.payload.navigate("/login")

      
      } else {
        toast.error(response.data?.message);
        yield put(setpasswordFailure(response))
      }
    } catch(err){
      toast.error(err?.response.data?.message);
      yield put(setpasswordFailure(err.data))
      console.log("saga",err)
    }
}

function* CreatePaymentRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(postApi, 'customer/create-direct-payment-intent', action.payload.obj,  header);
      if(response.data.status){
        yield put(CreatePaymentSuccess(response.data))
        toast.success(response.data?.message)
        let obj = {
          clientSecret: response.data?.data?.paymentIntent
      }
        action.payload.navigate("/order-payment",{state:{
          response: response.data?.data,
          secret: obj,
          obj1:action.payload.obj1
        }
      })
      } else {
        yield put(CreatePaymentSuccess(response.data))
      }
    } catch(err){
      yield put(CreatePaymentFailure(err.data))
    }
}

function* OrderConfirmPaymentRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(postApi, 'customer/verify-direct-payment', action.payload.obj, header);
      if(response.data.status){
        yield put(OrderConfirmPaymentSuccess(response.data))
        //toast.success(response.data?.message)
        //yield call(SlotpaymentRequest);
     
         let responseverify = yield call(postApi, 'customer/slot-payment', action.payload.obj1, header);
         if(responseverify.data.status){
          yield put(SlotpaymentSuccess(response.data))
          toast.success(responseverify.data?.message);
          action.payload.navigate("/order-history")
        } else {
          toast.error(responseverify.data?.message);
          yield put(SlotpaymentSuccess(responseverify.data))
        }

      } else {
        yield put(OrderConfirmPaymentSuccess(response.data))
      }
    } catch(err){
      yield put(OrderConfirmPaymentFailure(err.data))
    }
}

const watchFunction = [

  

  (function* () {
    yield takeLatest('FetchDataReducer/OrderConfirmPaymentRequest', OrderConfirmPaymentRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/CreatePaymentRequest', CreatePaymentRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/setpasswordRequest', setpasswordRequest)
  })(),
  

  (function* () {
    yield takeLatest('FetchDataReducer/ForgotpasswordRequest', ForgotpasswordRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/UpdateProfileRequest', UpdateProfileRequest)
  })(),
  
  (function* () {
    yield takeLatest('FetchDataReducer/GetaboutRequest', GetaboutRequest)
  })(),
  

  (function* () {
    yield takeLatest('FetchDataReducer/ConfirmPaymentRequest', ConfirmPaymentRequest)
  })(),
  
  (function* () {
    yield takeLatest('FetchDataReducer/PaymentRequest', PaymentRequest)
  })(),
  
  

  (function* () {
    yield takeLatest('FetchDataReducer/GetProcessRequest', GetProcessRequest)
  })(),


  (function* () {
    yield takeLatest('FetchDataReducer/GetProfileRequest', GetProfileRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/GetWalletRequest', GetWalletRequest)
  })(),
  
  (function* () {
    yield takeLatest('FetchDataReducer/GetsupportRequest', GetsupportRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/GetFaqRequest', GetFaqRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/ZoneRequest', ZoneRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/SignupRequest', SignupRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/LoginRequest', LoginRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/ResetOTPRequest', ResetOTPRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/ValidateotpRequest', ValidateotpRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/ResendOTPRequest', ResendOTPRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/CategoryRequest', CategoryRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/ProductListRequest', ProductListRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/DashboardBannerRequest', DashboardBannerRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/GetSlotListRequest', GetSlotListRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/GetPickupTimeSlotRequest', GetPickupTimeSlotRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/GetDeliveryTimeSlotRequest', GetDeliveryTimeSlotRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/SlotbookRequest', SlotbookRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/SlotDetailsRequest', SlotDetailsRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/GetCouponRequest', GetCouponRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/SlotpaymentRequest', SlotpaymentRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/GetOrderHistoryRequest', GetOrderHistoryRequest)
  })(),
  
  (function* () {
    yield takeLatest('FetchDataReducer/OrderDetailsRequest', OrderDetailsRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/GetInvoiceRequest', GetInvoiceRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/SavereviewRequest', SavereviewRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/GetReviewRequest', GetReviewRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/GetbannerRequest', GetbannerRequest)
  })(),

  (function* () {
    yield takeLatest('FetchDataReducer/GetserviceRequest', GetserviceRequest)
  })(),
  
  

  ];


export default watchFunction;