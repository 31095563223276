import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

const ConfirmOrderSummery = ({amount}) => {

    const [summerydata, setsummerydata] = useState([])
    const fetchReducer = useSelector((state) => state.FetchDataReducer)
    var item = fetchReducer.SlotDetailsResponse?.data?.itemDetails?.items

    console.log(item)
   
useEffect(()=>{

    let MainArr = []

    item&&item.forEach((element, i) => {
        let totalamount = 0 
        let innArr = []
        element.details.forEach((details, i) => {
            totalamount += Number(details?.price?.$numberDecimal)
            innArr.push({
                service_name:details?.service_name,
                qty:details?.quantity,
                eachprice:details?.price?.$numberDecimal
            })
        })
        MainArr.push({
            item_name: element?.itemId?.name, 
            totalamount:totalamount,
            services:innArr
        })
        
    });
    setsummerydata(MainArr)

},[fetchReducer.SlotDetailsResponse])




  return (
    <div className="rvew-right p-0">
    <div className="table-responsive">
        <h4>Your Order Summary</h4>
        <table className="table">
            <thead>
                <tr>
                    <th >Item</th>
                    <th >Qty</th>
                    <th >Last</th>

                </tr>
            </thead>
            
            <tbody>
                {summerydata&&summerydata.map((data, i)=>{
                 
                    return (
                        <tr>
                             <td><i className="fa-solid fa-check"></i> {data.item_name}</td>
                             <td>
                             {
                            data.services&&data.services.map((items, i)=>{
                               
                                return (
                                    
                                   <label className='d-block mb-0'>{items?.service_name} : <b>{items?.qty}</b> = AED {items?.eachprice}</label>   
                                )
                            })
                        }
                        </td>
                         <td>
                       

                        <span>AED {data?.totalamount} </span>
                       </td>
                        </tr>
                    )
                 
                   
                })}
                

                {/* <tr>
                    <td><i className="fa-solid fa-check"></i> {washdata.name}  </td>
                     <td>{washdata.qty}</td>
                    <td>AED {washdata.amount}</td>
                </tr>

                <tr>
                    <td><i className="fa-solid fa-check"></i> {irondata.name} </td>
                      <td>{irondata.qty}</td>
                    <td>AED {irondata.amount}</td>
                </tr> */}
                <tr className="grand-totl">
                    <td colspan="3">
                        Grand Total : <span> AED {amount}</span>
                    </td>

                </tr>
            </tbody>

        </table>

     
    </div>
</div>
  )
}

export default ConfirmOrderSummery