import React, { useState } from 'react'
import {loadStripe} from '@stripe/stripe-js';
import { useElements, PaymentElement,linkAuthenticationElement, CardElement, Elements} from '@stripe/react-stripe-js';
import CheckoutForm from "./CheckoutForm";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from '../../utils/Loader';
import Constant from '../../utils/Constant';

const PackagePayment = () => {
    const  location  = useLocation();

    const [clientsecret, setclientsecret] = useState(location?.state?.secret)

    const stripePromise = loadStripe(Constant.STRIPE_KEY);
    const [loading, setloading] = useState(false)

    console.log("payment", location?.state?.response)


  return (
    <>
    {loading && <Loader/>}

  <div className="container">
       <div className="dashboard-panel p-5 payment-page">
       {clientsecret &&
                   <Elements stripe={stripePromise} options={clientsecret}>
                       <CheckoutForm  orderid={location?.state?.response?.stripeOrderId} />
        </Elements>
}
       </div>
    </div>


</>
  )
}

export default PackagePayment