import React, { useEffect, useState } from 'react'
import {CategoryRequest, GetSlotListRequest, ProductListRequest} from '../../redux/reducer/FetchDataReducer'
import { useSelector, useDispatch } from 'react-redux'
import Loader from '../../utils/Loader'
import DashboardBanner from './DashboardBanner'
import DashboardOrderSummery from './DashboardOrderSummery'
import { ProductAction, serviceDryAction, serviceWashAction, serviceIronAction, BookingserviceDryAction, BookingserviceWashAction, BookingserviceIronAction } from '../../redux/reducer/ProductReducer'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';

var status = ''

const Dashboard = () => {



  const fetchReducer = useSelector((state) => state.FetchDataReducer)
  
  const fetchproductReducer = useSelector((state) => state.Productdatareducer)



  var categorylist = fetchReducer?.CategoryListResponse?.data?.categories
  var productlist = fetchReducer?.productListResponse?.data?.products

  const [show, setshow] = useState("")
  const [Plist, setPlist] = useState([])
  const [localArray, setlocalArray] = useState([])
  const [pid, setpid] = useState(null)
 



  const dispatch = useDispatch()
  let navigate = useNavigate()

  useEffect(()=>{
      dispatch(CategoryRequest({navigate: navigate}))

  },[])

  useEffect(()=>{
      setshow(fetchReducer?.CategoryListResponse?.data?.categories[0]?.categoryName)
      status = fetchReducer.status;
     let obj= {
       "categoryId": fetchReducer?.CategoryListResponse?.data?.categories[0]?._id,
     }
      dispatch(ProductListRequest(obj))

       

},[fetchReducer?.CategoryListResponse.status])

useEffect(()=>{
    

    let customArr = []

   

    productlist&&productlist.forEach(element => {

          var serviceList = [];
       



      
        
        element.services&&element.services.forEach(service =>{
            serviceList.push({
                service_id:service._id,
                service_name:service.service_name,
                service_price:service.service_price.$numberDecimal,
                sub_total:0,
                quantity:0
            })
        })
        customArr.push({
            cate_id:element?.categoryId,
            product_id:element._id,
            name:element.name,
            image:element.image,
            service_list: serviceList
        })   
        });

        let TempArr = []
        
        customArr.forEach(obj => {
            var flag = 0;
            localArray.forEach(element => {
                if(obj?.product_id == element?.product_id){
                    TempArr.push({
                        ...obj, service_list:element?.service_list
                    })
                    flag = 1
                } 


            });

          if(flag == 0){
            TempArr.push(obj)
          }  else {
            flag = 0
          }

        });

        if(localArray.length == 0){
            setPlist(customArr)
            dispatch(ProductAction(customArr))
        } else {
            setPlist(TempArr)
            dispatch(ProductAction(TempArr))
        }
       
     



    //   dispatch(serviceDryAction({
    //     qty:0,
    //     subtotal:0
    //   }))
    //   dispatch(serviceWashAction({
    //     qty:0,
    //     subtotal:0
    //   }))
    //   dispatch(serviceIronAction({
    //     qty:0,
    //     subtotal:0
    //   }))

},[productlist])


  const categoryHandle = (id, catename) =>{
    setshow(catename)

    let obj= {
      "categoryId": id,
    }
    dispatch(ProductListRequest(obj))
  }


  const IncrementHandle = (pindex, cindex, product_id, cat_id, item) => {
    let arr = JSON.parse(JSON.stringify(Plist));



    arr[pindex].service_list[cindex].quantity = arr[pindex].service_list[cindex].quantity + 1
    arr[pindex].service_list[cindex].sub_total = arr[pindex].service_list[cindex].quantity * Number(arr[pindex].service_list[cindex].service_price)
    setPlist(arr)

    let arr2 = JSON.parse(JSON.stringify(localArray))

    const items = arr2.find((items) => items?.cate_id ===  cat_id);


console.log(arr2.findIndex((obj) => obj.cat_id == items?.cat_id))

    if(arr2.findIndex((obj) => obj.product_id == product_id) == -1){
        arr2.push(arr[pindex])
    } else {
        arr2.splice(arr2.findIndex((obj) => obj.product_id == product_id), 1)
        arr2.push(arr[pindex])
        
    }

    setlocalArray(arr2)


    // var obj = {
    //     cate_id:cat_id,
    //     product_id:product_id,
    //     service:1
    // }

   // console.log('obj', items)

    

   // setlocalArray([...localArray, obj]);

   let TotalDryQty = 0;
   let Drysum = 0;
   let TotalWashQty = 0;
   let Washsum = 0;
   let TotalIronQty = 0;
   let Ironsum = 0;
   let TotalbDryQty = 0;
   let bDrysum = 0;
   let TotalbWashQty = 0;
   let bWashsum = 0;
   let TotalbIronQty = 0;
   let bIronsum = 0;


    arr2.forEach(data => {
        data.service_list.forEach((value, index)  => {
          
            if(index === 0) {
             
                dispatch(serviceDryAction({
                    qty: TotalDryQty += value.quantity,
                    subtotal: Drysum += value.sub_total,
                    name:data?.name
                }))

                dispatch(BookingserviceDryAction({
                    qty: TotalbDryQty += value.quantity,
                    subtotal: bDrysum += value.sub_total,
                    name:data?.name
                }))

                
             
            } else if(index === 1) {
                
                dispatch(serviceWashAction({
                    qty: TotalWashQty += value.quantity,
                    subtotal: Washsum += value.sub_total,
                    name:data?.name
                }))
                dispatch(BookingserviceWashAction({
                    qty: TotalbWashQty += value.quantity,
                    subtotal: bWashsum += value.sub_total,
                    name:data?.name
                }))
             
            } else if(index === 2) {
            
                dispatch(serviceIronAction({
                    qty: TotalIronQty += value.quantity,
                    subtotal: Ironsum += value.sub_total,
                    name:data?.name
                }))
                dispatch(BookingserviceIronAction({
                    qty: TotalbIronQty += value.quantity,
                    subtotal: bIronsum += value.sub_total,
                    name:data?.name
                }))
            } 
        });
        
    });


   

  }

  const DecrementHandle = (pindex, cindex, product_id, cat_id, item) => {
    let arr = JSON.parse(JSON.stringify(Plist));
    if(arr[pindex].service_list[cindex].quantity > 0){
        arr[pindex].service_list[cindex].quantity = arr[pindex].service_list[cindex].quantity - 1
        arr[pindex].service_list[cindex].sub_total = arr[pindex].service_list[cindex].quantity * Number(arr[pindex].service_list[cindex].service_price)
        setPlist(arr)



        let arr2 = JSON.parse(JSON.stringify(localArray))

        const items = arr2.find((items) => items?.cate_id ===  cat_id);
    
    
    console.log(arr2.findIndex((obj) => obj.cat_id == items?.cat_id))
    
        if(arr2.findIndex((obj) => obj.product_id == product_id) == -1){
            arr2.push(arr[pindex])
        } else {
            arr2.splice(arr2.findIndex((obj) => obj.product_id == product_id), 1)
            arr2.push(arr[pindex])
            
        }
    
        setlocalArray(arr2)







        let TotalDryQty = 0;
        let Drysum = 0;
        let TotalWashQty = 0;
        let Washsum = 0;
        let TotalIronQty = 0;
        let Ironsum = 0;
        let TotalbDryQty = 0;
        let bDrysum = 0;
        let TotalbWashQty = 0;
        let bWashsum = 0;
        let TotalbIronQty = 0;
        let bIronsum = 0;


        arr2.forEach(data => {
            data.service_list.forEach((value, index)  => {
    
                if(index === 0) {
                    dispatch(serviceDryAction({
                        qty: TotalDryQty += value.quantity,
                        subtotal: Drysum += value.sub_total
                    }))
                    dispatch(BookingserviceDryAction({
                        qty: TotalbDryQty += value.quantity,
                        subtotal: bDrysum += value.sub_total,
                        name:data?.name
                    }))
                  
                } else if(index === 1) {
                   
                    dispatch(serviceWashAction({
                        qty: TotalWashQty += value.quantity,
                        subtotal: Washsum += value.sub_total
                    }))
                    dispatch(BookingserviceWashAction({
                        qty: TotalbWashQty += value.quantity,
                        subtotal: bWashsum += value.sub_total,
                        name:data?.name
                    }))
                 
                } else if(index === 2) {
                    dispatch(serviceIronAction({
                        qty: TotalIronQty += value.quantity,
                        subtotal: Ironsum += value.sub_total
                    }))
                    dispatch(BookingserviceIronAction({
                        qty: TotalbIronQty += value.quantity,
                        subtotal: bIronsum += value.sub_total,
                        name:data?.name
                    }))
                } 
            });
            
        });
    }
  
  }


  const NextToSlot = ()=>{
   
    if(fetchproductReducer?.serviceDry?.qty > 0 || fetchproductReducer?.serviceWash?.qty > 0 ||fetchproductReducer?.serviceIron?.qty > 0){
    
        let FinalArr = []

        localArray.forEach(elam =>{
            var serviceList = [];
            elam.service_list.forEach(item =>{
                if(item.quantity > 0){
                    serviceList.push({
                        "service_name":item.service_name,
                        "quantity":item.quantity,
                        "price": item.sub_total
                    })
                }
            })
            if(serviceList.length > 0){
                FinalArr.push({
                    "itemId": elam.product_id,
                    "details":serviceList
                })
            }
            
        })

        dispatch(GetSlotListRequest({obj:{"items":FinalArr},navigate:navigate}))
        // navigate("/slot-booking")
        // dispatch(ProductAction(Plist))
    } else {
        toast.error('Select any one');
    }

    
  }

  useEffect(() =>{
    window.scrollTo(0,0)
  },[])
  console.log("Local", localArray)
  console.log("main", Plist)
  return (
    <>
    <Loader visible={fetchReducer.isloading} />
        <DashboardBanner/>
    <section className="dash-pills">
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                    <div className="tab-dashbrd">
                    <div className='custom-tabs'>
                        <ul>
                          {categorylist&&categorylist.map((category, index) =>{
                            return (
                              <li key={index}>
                                  <button onClick={()=>categoryHandle(category?._id, category?.categoryName)} className={`${show == category?.categoryName ? 'active' : ''} `}>{category?.categoryName}</button>
                              </li>
                            )
                          })}
                          
                        </ul>
                    </div>
                      {Plist&&Plist.length > 0 ?
                    <div className="row">

                      {Plist&&Plist.map((product, parentindex)=>{
                        return (
                          <div className="col-lg-6" key={parentindex}>
                                <div className='product-container'>
                          
                                  <div className="prdct-arrow-box sm-blck">
                                      <div className="prdct-arrow-img">
                                          <img src={fetchReducer?.productListResponse?.data?.baseUrl + product.image[0]} alt="" />
                                      </div>


                                      <div className="prdct-arrow-content">
                                          <div className="prdct-arrow-hdng">
                                              <h4>{product?.name}</h4>
                                             <i className="fa-solid fa-chevron-down"></i>
                                          </div>
                                          <table className="table">
                                              <thead>
                                                  <tr>
                                                  {
                                                        Number(product?.service_list&&product?.service_list[0]?.service_price) !== 0.00 &&

                                                        <th scope="col">{product?.service_list&&product?.service_list[0]?.service_name}</th>
                                                    }
                                                       {
                                                        Number(product?.service_list&&product?.service_list[1]?.service_price) !== 0.00 &&

                                                        <th scope="col">{product?.service_list&&product?.service_list[1]?.service_name}</th>
                                                    }
                                                       {
                                                        Number(product?.service_list&&product?.service_list[2]?.service_price) !== 0.00 &&

                                                        <th scope="col">{product?.service_list&&product?.service_list[2]?.service_name}</th>
                                                    }
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr>
                                                    {
                                                        Number(product?.service_list&&product?.service_list[0]?.service_price) !== 0.00 &&

                                                        <td>AED {product?.service_list&&product?.service_list[0]?.service_price}</td>
                                                    }

{
                                                        Number(product?.service_list&&product?.service_list[1]?.service_price) !== 0.00 &&

                                                        <td>AED {product?.service_list&&product?.service_list[1]?.service_price}</td>
                                                    }

                                                {
                                                        Number(product?.service_list&&product?.service_list[2]?.service_price) !== 0.00 &&

                                                        <td>AED {product?.service_list&&product?.service_list[2]?.service_price}</td>
                                                    }
                                                     
                                                 
                                                    
                                                  </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                     
                                  </div>
                                  <ul className="navigation">
                             {product?.service_list
&&product?.service_list
.map((services, childindex)=>{

    if(Number(services?.service_price) != 0.00){
        return (


            <li className={`n${childindex + 1}`} key={childindex}>
                  <div className="prdct-arrow-box-open">
                      <div className="table-open">
                          <p>{services?.service_name}</p>
                          <h6>AED {services?.service_price}</h6>
                        <div className='quantity-part'>
                            <button className="value-button" onClick={()=>DecrementHandle(parentindex, childindex, product?.product_id, product?.cate_id, product
)}>-</button>
                            <span className='number'>{services?.quantity}</span>
                            <button className="value-button" onClick={()=>IncrementHandle(parentindex, childindex, product?.product_id, product?.cate_id, product)}>+</button>
                        </div>
                         
                        </div>
                  </div>
              </li>

         
          )
    }
                             
                             })}
                             
                         
                            </ul>
                                  </div>
                                 
                      </div>
                        )
                      })}
                                   
                                   
                                </div>

                                :
                      <h3>No Data Found</h3>

                    }
                    </div>
                </div>
                <div className="col-lg-4">
                    <DashboardOrderSummery />
                    
                <button className="dwnld-invc" onClick={NextToSlot}>Next</button>
               
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Dashboard