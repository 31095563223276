import React, { useState } from 'react'
import { IMAGE } from '../../utils/Theme'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import {ForgotpasswordRequest, LoginRequest} from '../../redux/reducer/FetchDataReducer'
import Loader from '../../utils/Loader'
import { useEffect } from 'react';
import { initOTPless } from '../../utils/initOtpless';
import { SavemobileNoAction } from '../../redux/reducer/ProductReducer';

const ForgotPassword = () => {

    const [mobile, setmobile] = useState("")
    const [password, setpassword] = useState("")
    const fetchReducer = useSelector((state) => state.FetchDataReducer)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    

    useEffect(() => initOTPless(callback), []);

    const callback = (otplessUser) => {
            var mobile = otplessUser?.identities[0]?.identityValue.substring(3)

            navigate('/change-password')
    
        
      dispatch(SavemobileNoAction({mobile}))
    };
    
      const LoginHandler = () => {
        if(mobile == ''){
          toast.error('Phone is required');
        } else if(mobile.length !== 9){
          toast.error('Phone no should be 9 digit');
        }  else {
          let body = {
            "phone":mobile,
        }
        
        dispatch(ForgotpasswordRequest({obj:body,navigate:navigate, phoneno:mobile}))
    
        }
      }








  return (
    <section className="sign-up py-5">
       <Loader visible={fetchReducer.isloading} />
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
        {/* <div id="otpless-login-page"></div> */}
          <div className="sign-up-box forgot-password-page">
            <h3>Forgot Password</h3>
    
            <div id="otpless-login-page"></div>
          
            <div className='form-group'>
              
              <p>Back to login  <Link to="/login">Click Here</Link></p>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
        <div className="sign-up-img">
        {/* <p>Don't Have an account ? <Link to="/signup">Register Here</Link></p> */}
                  <img src={IMAGE.authImage} alt="" />
                </div>

        </div>
      </div>
    </div>
  </section>
  )
}

export default ForgotPassword