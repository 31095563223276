import React, { useEffect, useState } from "react";
import {
    GetWalletRequest,
    PaymentRequest,
} from "../../redux/reducer/FetchDataReducer";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../utils/Loader";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Accordion from 'react-bootstrap/Accordion';

const Wallet = () => {

    const fetchReducer = useSelector((state) => state.FetchDataReducer);

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [amount, setamount] = useState('');
  
  
  useEffect(()=>{
      dispatch(GetWalletRequest())
  },[])


  const WalletHandle = () => {
    if(amount == ''){
        toast.error('Amount is required')
    } else {
        let obj = {
            'amount': amount,
          }
    
          dispatch(PaymentRequest({obj: obj,navigate:navigate}))
    }
  }


  return (
    <section className="faq-sec">
        <Loader visible={fetchReducer.isloading} />
        <div className="container">
        <div className="history-hdr">
                    <h3>Wallet Recharge</h3>
                 </div>
          
                
                <div className="wallet-box">
                    <div className="balance-box">
                        <img src="assets/images/Icon awesome-wallet.png" alt="" />
                        <p>Balance</p>
                        <h2>AED {fetchReducer?.GetWalletResponse?.wallet?.amount?.$numberDecimal}</h2>
                    </div>
                    <div className="wallet-btn">
                        <div className="row">
                            <div className="col-lg-4">
                             
                            <input type="text" className="form-control" placeholder="Enter Amount "
                                value={amount}
                                onChange={(e)=>setamount(e.target.value)}

                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            </div>
                            <div className="col-lg-2">
                            <button className="btn btn-primary" onClick={WalletHandle}>Add Wallet</button>
                            </div>
                        </div>
                      
                    </div>
                </div>
            
        </div>
    </section>
  )
}

export default Wallet