import React, { useEffect } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { IMAGE } from '../../utils/Theme'
import {  OrderConfirmPaymentRequest } from '../../redux/reducer/FetchDataReducer'
import { useDispatch } from 'react-redux'

const OrderPaymentSusscess = () => {

    const dispatch = useDispatch()
    const  location  = useLocation();

    useEffect(()=>{

        const FinalSuccess = async () => {
            let obj = {
                'stripeOrderId': location?.state?.orderid,
              }
        
              dispatch(OrderConfirmPaymentRequest({obj: obj, obj1:location?.state?.obj1}))
}
FinalSuccess()
    },[])



  return (
    <div className='success-sec'>
    <div className='success-box'>
        <img src={IMAGE.succes_image} />
        <h5 className='my-3'>Thanks for purchase</h5>
        <NavLink to="/order-history" className="btn btn-primary">Order History</NavLink>
    </div>

</div>
  )
}

export default OrderPaymentSusscess