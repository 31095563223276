import React, { useState, useRef,useEffect } from "react";
import { IMAGE } from '../../utils/Theme'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import {ResetOTPRequest, ValidateotpRequest, ResendOTPRequest} from '../../redux/reducer/FetchDataReducer'
import Loader from '../../utils/Loader'



const OTP = () => {

  const location = useLocation();
  let  mobile  = location.state;
 var str = String(mobile?.otp)
  console.log(str)

    const [otp1, setotp1] = useState(str[0])
    const [otp2, setotp2] = useState(str[1])
    const [otp3, setotp3] = useState(str[2])
    const [otp4, setotp4] = useState(str[3])
    const [counter, setCounter] = useState(59);

    const textInput1 = useRef(null);
    const textInput2 = useRef(null);
    const textInput3 = useRef(null);
    const textInput4 = useRef(null);

 

    const fetchReducer = useSelector((state) => state.FetchDataReducer)
    const dispatch = useDispatch()
    let navigate = useNavigate();


    useEffect(() => {
      const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      if(counter === 0)
      {
        ResetOTP()
      }
      return () => clearInterval(timer);
    }, [counter]);


    const VerifyHandler = () => {

      if(otp1 == '' || otp2 == '' || otp3 == '' || otp4 == ''){
        toast.error('OTP is required');
      } else {

      let obj= {
        "phone":mobile?.mobileno,
        "otp":otp1 + otp2 + otp3 + otp4
    }
      
      dispatch(ValidateotpRequest({obj:obj,navigate:navigate}))
  }
    }

    const ResetOTP = () => {
      let obj = {
        "phone":mobile?.mobileno
    }
      dispatch(ResetOTPRequest(obj))
    };

    const ResendOTP = () => {
      setCounter(59)
      let obj = {
        "phone":mobile?.mobileno
    }

      dispatch(ResendOTPRequest(obj))
    };


  return (
    <section className="sign-up py-5">
       <Loader visible={fetchReducer.isloading} />
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-5">
          <div className="sign-up-box">
            <h3>OTP</h3>
            <p>Please verify 4 digit OTP received on your mobile number <span style={{color:'#15B9A9'}}>*****{mobile?.mobileno?.slice(mobile.mobileno.length - 4)}</span></p>
            <div className='form-group'>
        <ul className="otp-l">
                    <li>
                    <input
                  type="text"
                  maxLength="1"
                  ref={textInput1}
                  onKeyUp={(e) => {
                    if (otp1 !== "") {
                      textInput2.current.focus();
                    } else if (otp1 === "") {
                      textInput1.current.focus();
                    }
                  }}
                  className="form-control input-style"
                  placeholder="-"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={otp1}
                  onChange={(e) => {
                    setotp1(e.target.value);
                }}
                /> 
                    </li>
                    <li>
                    <input
                  type="text"
                  maxLength="1"
                  ref={textInput2}
                  onKeyUp={(e) => {
                    if (otp2 !== "") {
                      textInput3.current.focus();
                    } else if (otp2 === "") {
                      textInput1.current.focus();
                    }
                  }}
                  className="form-control input-style"
                  placeholder="-"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={otp2}
                  onChange={(e) => {
                    setotp2(e.target.value);
                    
                }}
                /> 
                    </li>
                    <li>
                    <input
                  type="text"
                  maxLength="1"
                  ref={textInput3}
                  onKeyUp={(e) => {
                    if (otp3 !== "") {
                      textInput4.current.focus();
                    } else if (otp3 === "") {
                      textInput2.current.focus();
                    }
                  }}
                  className="form-control input-style"
                  placeholder="-"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={otp3}
                  onChange={(e) => {
                    setotp3(e.target.value);
                   
                }}
                /> 
                    </li>
                    <li>
                    <input
                  type="text"
                  maxLength="1"
                  ref={textInput4}
                  onKeyUp={(e) => {
                    if (otp4 !== "") {
                      textInput4.current.focus();
                    } else if (otp4 === "") {
                      textInput3.current.focus();
                    }
                  }}
                  className="form-control input-style"
                  placeholder="-"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={otp4}
                  onChange={(e) => {
                    setotp4(e.target.value);
                    
                }}
                /> 
                    </li>
                </ul>
        </div>
        <div className='form-group'>
                <button className='themeBtn' onClick={VerifyHandler}>Verify</button>
            </div>
            <h6 className="counter-txt text-center">00:{counter}</h6>
            <div className="text-center form-group">
            {counter === 0 ? (
              <>
                <h6>Don't receive an OTP?  <button className="rsnd-otp" onClick={ResendOTP}>
                    Resend OTP
                  </button></h6>
                 
                 
                  </>
                ) : null}
              </div>
          </div>
        </div>
        <div className="col-lg-7">
        <div className="col-lg-12 sign-up-img">
                  <img src={IMAGE.authImage} alt="" />
                </div>

        </div>
      </div>
    </div>
  </section>
  )
}

export default OTP