import React, { useState } from 'react'
import { IMAGE } from '../../utils/Theme'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import {ForgotpasswordRequest, LoginRequest, setpasswordRequest} from '../../redux/reducer/FetchDataReducer'
import Loader from '../../utils/Loader'
import { useEffect } from 'react';

const SetPassword = () => {


    const [mobile, setmobile] = useState("")
    const [password, setpassword] = useState("")
    const [cpassword, setcpassword] = useState("")
    const fetchReducer = useSelector((state) => state.FetchDataReducer)
    const fetchdataReducer = useSelector((state) => state.Productdatareducer)
    const dispatch = useDispatch()
    let navigate = useNavigate();

    console.log(fetchdataReducer)
    
    
      const LoginHandler = () => {
        if(password == ''){
          toast.error('Password is required');
        } else if(password != cpassword){
          toast.error('Password does not match');
        }  else {
          let body = {
            "phone": fetchdataReducer?.mno?.mobile,
            "password": password,
            "confirm_password": cpassword
        }
        
        dispatch(setpasswordRequest({obj:body,navigate:navigate}))
    
        }
      }






  return (
    <section className="sign-up py-5">
    <Loader visible={fetchReducer.isloading} />
 <div className="container">
   <div className="row">
     <div className="col-lg-5">
     {/* <div id="otpless-login-page"></div> */}
       <div className="sign-up-box">
         <h3>Set New Password</h3>
 
         <div className='form-group'>
             <input type="password" className="form-control" placeholder='Enter New Password' value={password} onChange={(e)=>setpassword(e.target.value)}/>
           
         </div>
         <div className='form-group'>
             <input type="password" className="form-control" placeholder='Enter Confirm Password' value={cpassword} onChange={(e)=>setcpassword(e.target.value)}/>
           
         </div>
         <div className='form-group'>
             <button className='themeBtn' onClick={LoginHandler}>Submit</button>
           <p>Back to login  <Link to="/login">Click Here</Link></p>
         </div>
       </div>
     </div>
     <div className="col-lg-7">
     <div className="sign-up-img">
     {/* <p>Don't Have an account ? <Link to="/signup">Register Here</Link></p> */}
               <img src={IMAGE.authImage} alt="" />
             </div>

     </div>
   </div>
 </div>
</section>
  )
}

export default SetPassword