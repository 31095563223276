
import { IMAGE } from '../../utils/Theme'
import React, { useEffect, useState } from 'react'
import {GetbannerRequest } from '../../redux/reducer/FetchDataReducer'
import { useSelector, useDispatch } from 'react-redux'
import Loader from '../../utils/Loader'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';

const Banner = () => {

  const fetchReducer = useSelector((state) => state.FetchDataReducer)

  const dispatch = useDispatch()
  let navigate = useNavigate()




  useEffect(()=>{
    dispatch(GetbannerRequest())

},[])

  return (
    <section className="banner-sec">
    <div className="container">

      <div className="bnr-sec">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <h6>{fetchReducer?.GetbannerResponse?.data?.subHeading}</h6>
            <h2>{fetchReducer?.GetbannerResponse?.data?.heading}</h2>
            <p>{fetchReducer?.GetbannerResponse?.data?.description}</p>
          </div>
          <div className="col-lg-6">
            <img src={IMAGE.banner} />
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Banner