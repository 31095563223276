
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import HomeIndex from "./component/home/HomeIndex";
import Header from "./component/header/Header";
import NotFound from "./component/NotFound";
import Signup from "./component/auth/Signup";
import Login from "./component/auth/Login";
import Footer from "./component/footer/Footer";
import OTP from "./component/auth/OTP";
import Dashboard from "./component/dashboard/Dashboard";
import PublicRoute from "./utils/PublicRoute";
import PrivateRoute from "./utils/PrivateRoute";
import BookSlot from "./component/dashboard/BookSlot";
import ConfirmOrder from "./component/dashboard/ConfirmOrder";
import OrderHistory from "./component/order/OrderHistory";
import OrderDetails from "./component/order/OrderDetails";
import About from "./component/cms/About";
import Faq from "./component/cms/Faq";
import HelpSupport from "./component/cms/HelpSupport";
import PackageList from "./component/package/PackageList";
import Wallet from "./component/wallet/Wallet";
import Profile from "./component/profile/Profile";
import Process from "./component/cms/Process";
import PackagePayment from "./component/package/PackagePayment";
import PaymentSuccess from "./component/package/PaymentSuccess";
import VerifyOtp from "./component/auth/VerifyOtp";
import ForgotPassword from "./component/auth/ForgotPassword";
import SetPassword from "./component/auth/SetPassword";
import OrderPayment from "./component/dashboard/OrderPayment";
import OrderPaymentSusscess from "./component/dashboard/OrderPaymentSusscess";

function App() {
  return (
    <div className="App">
         <HashRouter>
          <Header/>
         <Routes>
         <Route element={<PrivateRoute />}> 
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/otp" element={<OTP />} />
            <Route path="/verify-otp" element={<VerifyOtp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/change-password" element={<SetPassword />} />
       </Route> 
       <Route element={<PublicRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/slot-booking" element={<BookSlot />} />
            <Route path="/confirm-order" element={<ConfirmOrder />} />
            <Route path="/order-history" element={<OrderHistory />} />
            <Route path="/order-details/:id" element={<OrderDetails />} />
            <Route path="/package" element={<PackageList />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/payment" element={<PackagePayment />} />
            <Route path="/payment/success/:id" element={<PaymentSuccess />} />
            <Route path="/order-payment" element={<OrderPayment />} />
            <Route path="/order-payment/success" element={<OrderPaymentSusscess />} />
        </Route>
        <Route path="/" element={<HomeIndex />} />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/support" element={<HelpSupport />} />
        <Route path="/process" element={<Process />} />
        <Route path="*" element={<NotFound />} />
        </Routes>
     <Footer/>
         </HashRouter>
    </div>
  );
}

export default App;
