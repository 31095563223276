
import React, { useState } from 'react'
import { IMAGE } from '../../utils/Theme'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import {LoginRequest, SignupRequest} from '../../redux/reducer/FetchDataReducer'
import Loader from '../../utils/Loader'
import { useEffect } from 'react';
import { initOTPless } from '../../utils/initOtpless';

const VerifyOtp = () => {
    const fetchReducer = useSelector((state) => state.FetchDataReducer)
    const dispatch = useDispatch()
    let navigate = useNavigate();






    useEffect(() => initOTPless(callback), []);

    const callback = (otplessUser) => {
            var mobile = otplessUser?.identities[0]?.identityValue.substring(3)

                var payload = JSON.parse(JSON.stringify(fetchReducer?.signupobj.obj))
                payload['phone'] = mobile
    
        
       dispatch(SignupRequest({obj:payload,navigate:navigate}))
    };



  return (
    <section className="sign-up py-5">
    <Loader visible={fetchReducer.isloading} />
 <div className="container">
   <div className="row">
     <div className="col-lg-5">
     <div id="otpless-login-page"></div>
   
     </div>
     <div className="col-lg-7">
     <div className="sign-up-img">
     {/* <p>Don't Have an account ? <Link to="/signup">Register Here</Link></p> */}
               <img src={IMAGE.authImage} alt="" />
             </div>

     </div>
   </div>
 </div>
</section>
  )
}

export default VerifyOtp