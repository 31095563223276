import React,{useState} from 'react'
import { IMAGE } from '../../utils/Theme'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import {signupdatarequest, SignupRequest} from '../../redux/reducer/FetchDataReducer'
import Loader from '../../utils/Loader'

const Signup = () =>{
  const [area, setarea] = useState("")
  const [mobile, setmobile] = useState("")
  const [building, setbuilding] = useState("")
  const [flatno, setflatno] = useState("")
  const [street, setstreet] = useState("")
  const [city, setcity] = useState("")
  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [password, setpassword] = useState("")

  const fetchReducer = useSelector((state) => state.FetchDataReducer)
  let navigate = useNavigate();
  const dispatch = useDispatch()

  const SignUpHandle = () =>{

    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

     if(name == ''){
      toast.error('Name is required');
    } else if(area == ''){
      toast.error('Zone is required');
    } else if(building == ''){
      toast.error('Building name is required');
    } else if(flatno == ''){
      toast.error('Flat no. is required');
    } else if(street == ''){
      toast.error('Street is required');
    } else if(city == ''){
      toast.error('City is required');
    }  else if(reg.test(email) === false){
      toast.error('Email should be proper');
    } else if(password == ''){
      toast.error('Password should be must');
    }  else {
        let body = {
          "role": "2",
          "userType":"customer",
          "firstName": name,
          "email": email,
          "deviceType": "web",
          "deviceToken": "zwsqwtfghjkl",
          "zone": area,
          "building_name": building,
          "flat_no": flatno,
          "street": street,
          "city": city,
          "password":password
      }

      dispatch(signupdatarequest({obj:body}))
      navigate('/verify-otp')

      //dispatch(SignupRequest({obj:body,navigate:navigate}))

    }

    
  }


    return (

      <section className="sign-up py-5">
         <Loader visible={fetchReducer.isloading} />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="sign-up-box">
                <h3>Sign Up</h3>

                {/* <div className='form-group'>
                    <input type="text" className="form-control" value={mobile} onChange={(e)=>setmobile(e.target.value)} maxLength="9"
                minLength="9"
                onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }} placeholder='Enter Mobile Number' />
                </div> */}
                <div className="form-group">
                        <input type="text" className="form-control" value={name} onChange={(e)=>setname(e.target.value)} placeholder="Enter Name" />
                      </div>
                <div className="form-group">
                        <select className="form-select" onChange={(e)=>setarea(e.target.value)}>
                          <option value="">Area or Zone</option>
                          {fetchReducer.ZoneListResponse?.data?.areas&&fetchReducer.ZoneListResponse?.data?.areas.map((item,index)=>{
                            return (
                              <option key={index} value={item?.area}>{item?.area}</option>
                            )
                          })}
                        </select>
                      </div>
                      <div className="form-group">
                        <input type="text" className="form-control" value={building} onChange={(e)=>setbuilding(e.target.value)} placeholder="Building Name" />
                      </div>
                      <div className="form-group">
                        <input type="text" className="form-control" value={flatno} onChange={(e)=>setflatno(e.target.value)} placeholder="Flat no." />
                      </div>
        
        
                      <div className="form-group">
                        <input type="text" className="form-control" value={street} onChange={(e)=>setstreet(e.target.value)} placeholder="Street or landmark" />
                      </div>
                      <div className="form-group">
                        <input type="text" className="form-control" value={city} onChange={(e)=>setcity(e.target.value)} placeholder="City" />
                      </div>
        
                      <div className="form-group">
                        <input type="email" className="form-control" value={email} onChange={(e)=>setemail(e.target.value)} placeholder="Email" />
                      </div>
                      <div className='form-group'>
                    <input type="password" className="form-control" placeholder='Enter Password' value={password} onChange={(e)=>setpassword(e.target.value)}/>
                  
                </div>
                <div className='form-group'>
                    <button className='themeBtn' onClick={SignUpHandle}>SignUp</button>
                  <p>If you have register? <button  onClick={()=>{
              navigate('/login')
            }}>Login Here</button> </p>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
            <div className="col-lg-12 sign-up-img">
                      <img src={IMAGE.authImage} alt="" />
                    </div>

            </div>
          </div>
        </div>
  </section>









    )
}


export default Signup