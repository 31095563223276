import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';

const MainHeader = () => {


  var token = localStorage.getItem('access_token');
    let navigate = useNavigate()
 

  const notify = () => {
    localStorage.clear();
    toast.success("Log out successfully done");
    navigate("/")
  }


  return (
    <div className='mainHeader'>
        <div className='container'>
        <ul>
            <li>
                <NavLink to="/"
                className={({ isActive }) => (isActive ? "active" : undefined)}
                >Home</NavLink>
            </li>
             {token ?  <li>
                <NavLink to="/dashboard" className={({ isActive }) => (isActive ? "active" : undefined)}>Create Order</NavLink>
            </li> :
             <li>
             <NavLink to="/login" className={({ isActive }) => (isActive ? "active" : undefined)}>Create Order</NavLink>
         </li>
            }   

            {token && 
            <>
           
            <li>
                <NavLink to="/package" className={({ isActive }) => (isActive ? "active" : undefined)}>Package</NavLink>
            </li>
            <li>
                <NavLink to="/order-history" className={({ isActive }) => (isActive ? "active" : undefined)}>Order History</NavLink>
            </li>
            {/* <li>
                <NavLink to="/profile" className={({ isActive }) => (isActive ? "active" : undefined)}>Profile</NavLink>
            </li> */}
           
            </>
}
{token && 
    
           
            <li>
                <NavLink to="/profile" className={({ isActive }) => (isActive ? "active" : undefined)}>Profile</NavLink>
            </li>
}
<li>
                <NavLink to="/process"
                className={({ isActive }) => (isActive ? "active" : undefined)}
                >Process</NavLink>
            </li>
            <li>
                <NavLink to="/about"
                className={({ isActive }) => (isActive ? "active" : undefined)}
                >About</NavLink>
            </li>
            <li>
                <NavLink to="/faq"
                className={({ isActive }) => (isActive ? "active" : undefined)}
                >FAQ</NavLink>
            </li>
            <li>
                <NavLink to="/support"
                className={({ isActive }) => (isActive ? "active" : undefined)}
                >Help & Support</NavLink>
            </li>
            {token && 
            <li>
                <button onClick={notify} >Log out</button>
            </li>}
        </ul>
        </div>
       
    </div>
  )
}

export default MainHeader