import React, { useEffect } from 'react'
import Banner from './Banner'
import AppDownload from './AppDownload'
import WorkProcess from './WorkProcess'
import OurServices from './OurServices'

const HomeIndex = () => {

  useEffect(() =>{
    window.scrollTo(0,0)
  },[])


  return (
    <>
        <Banner/>
        <AppDownload/>
        <WorkProcess/>
        <OurServices/>
    </>
  )
}

export default HomeIndex