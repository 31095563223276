import React, { useEffect } from 'react'
import { IMAGE } from '../../utils/Theme'
import {GetaboutRequest } from '../../redux/reducer/FetchDataReducer'
import { useSelector, useDispatch } from 'react-redux'
import Loader from '../../utils/Loader'
import { useNavigate } from 'react-router-dom'
const About = () => {

  const fetchReducer = useSelector((state) => state.FetchDataReducer)

  const dispatch = useDispatch()
  let navigate = useNavigate()

  useEffect(() =>{
    window.scrollTo(0,0)
    dispatch(GetaboutRequest())
  },[])

  return (
    <section className="faq-sec">
    <div className="container">
        <div className="row">
            <div className="col-lg-6">
                <h3>About Us</h3>
                <div dangerouslySetInnerHTML={{__html:fetchReducer?.GetAboutResponse?.aboutUs?.aboutUs}} />
           
            </div>
            
      
          <div className="col-lg-6 pdng-img">
                <img src={IMAGE.aboutimg} alt=""/>
            </div>
         
        </div>
    </div>
</section>
  )
}

export default About