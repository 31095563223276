import React, { useState } from 'react'
import { IMAGE } from '../../utils/Theme'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import {LoginRequest} from '../../redux/reducer/FetchDataReducer'
import Loader from '../../utils/Loader'
import { useEffect } from 'react';



const Login = () => {
const [mobile, setmobile] = useState("")
const [password, setpassword] = useState("")
const fetchReducer = useSelector((state) => state.FetchDataReducer)
const dispatch = useDispatch()
let navigate = useNavigate();


  const LoginHandler = () => {
    if(mobile == ''){
      toast.error('Phone is required');
    } else if(mobile.length !== 9){
      toast.error('Phone no should be 9 digit');
    } else if(password == ''){
      toast.error('Password should be must');
    } else {
      let body = {
        "phone":mobile,
        "password":password,
        "deviceToken":"wqwqeqeqeq",
        "userType":"customer"
    }
    
    dispatch(LoginRequest({obj:body,navigate:navigate, phoneno:mobile}))

    }
  }

  // useEffect(() => {
  //   window.otpless = (otplessUser) => {
 
  //     var mobile = otplessUser?.identities[0]?.identityValue.substring(3)

  //     let body = {
  //       "phone":mobile,
  //       "deviceToken":"wqwqeqeqeq",
  //       "userType":"customer"
  //   }
    
  //   dispatch(LoginRequest({obj:body,navigate:navigate, phoneno:mobile}))

  //   };
  //   //window.location.reload()
  // }, []);



  return (
    <section className="sign-up py-5">
       <Loader visible={fetchReducer.isloading} />
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
        {/* <div id="otpless-login-page"></div> */}
          <div className="sign-up-box">
            <h3>Login</h3>
    
            <div className='form-group login-phone'>
                <input type="text" className="form-control" placeholder='Enter mobile no.' value={mobile} onChange={(e)=>setmobile(e.target.value)} 
                maxLength="9"
                minLength="9"
                onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                />
                <span className='prfill'>+0</span>
            </div>
            <div className='form-group'>
                <input type="password" className="form-control" placeholder='Enter Password' value={password} onChange={(e)=>setpassword(e.target.value)}/>
              
            </div>
            <button className='themeBtn' onClick={LoginHandler}>Continue</button>
            <div className='row'>
              <div className='col-lg-6'>
              <div className='form-group'>
              
              <p>Don't Have an Account ? <Link to="/signup">Register Here</Link></p>
            </div>
              </div>
              <div className='col-lg-6'>
              <div className='form-group'>
              <p>Forgot Password ? <Link to="/forgot-password">Click Here</Link></p>
            </div>
              </div>
            </div>
           
          </div>
        </div>
        <div className="col-lg-6">
        <div className="sign-up-img">
        {/* <p>Don't Have an account ? <Link to="/signup">Register Here</Link></p> */}
                  <img src={IMAGE.authImage} alt="" />
                </div>

        </div>
      </div>
    </div>
  </section>
  )
}

export default Login