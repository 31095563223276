import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DashboardOrderSummery from './DashboardOrderSummery'
import { useNavigate } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GetPickupTimeSlotRequest, GetDeliveryTimeSlotRequest, SlotbookRequest } from '../../redux/reducer/FetchDataReducer';
import moment from "moment";
import { toast } from 'react-toastify';
import Loader from '../../utils/Loader'
import BookingOrderSummery from './BookingOrderSummery';
import { serviceDryAction, serviceIronAction, serviceWashAction } from '../../redux/reducer/ProductReducer';



const BookSlot = () => {

  var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);

  const [ChooseDate, setChooseDate] = useState(currentDate);
  const [ChoosedeliveryDate, setChoosedeliveryDate] = useState(new Date());
  const [selectday, setSelectDay] = useState("");
  const [pickupTime, setpickupTime] = useState("");
  const [deliveryTime, setdeliveryTime] = useState("");
    const fetchproductReducer = useSelector((state) => state.Productdatareducer)
    const fetchReducer = useSelector((state) => state.FetchDataReducer)
    const zone = localStorage.getItem('zone-area');


let navigate = useNavigate()
const dispatch = useDispatch()

useEffect(()=>{

    dispatch(serviceDryAction({
        qty:0,
        subtotal:0
      }))
      dispatch(serviceWashAction({
        qty:0,
        subtotal:0
      }))
      dispatch(serviceIronAction({
        qty:0,
        subtotal:0
      }))
  
    if (Object.getOwnPropertyNames(fetchproductReducer.ChooseproductList).length === 0) {
        navigate("/dashboard")
    } else  {
        let day = "";
      switch (ChooseDate.getDay()) {
        case 0:
          day = "sunday";
          break;
        case 1:
          day = "monday";
          break;
        case 2:
          day = "tuesday";
          break;
        case 3:
          day = "wednesday";
          break;
        case 4:
          day = "thursday";
          break;
        case 5:
          day = "friday";
          break;
        case 6:
          day = "saturday";
      }
      setSelectDay(day);
      let obj = {
        "area":fetchReducer?.GetProfileResponse?.userData?.detailsData?.zone,
        "date":moment(ChooseDate).format("YYYY-MM-DD"),
        "day":day,
        "type":"pickup"
      }

      dispatch(GetPickupTimeSlotRequest(obj))
      }
    

},[fetchproductReducer.ZoneArea])


const DateHandle = async (date) => {


  setChooseDate(date)
  let day = "";
  switch (date.getDay()) {
    case 0:
      day = "sunday";
      break;
    case 1:
      day = "monday";
      break;
    case 2:
      day = "tuesday";
      break;
    case 3:
      day = "wednesday";
      break;
    case 4:
      day = "thursday";
      break;
    case 5:
      day = "friday";
      break;
    case 6:
      day = "saturday";
  }
  setSelectDay(day)

  let obj = {
    "area":fetchReducer?.GetProfileResponse?.userData?.detailsData?.zone,
    "date":moment(date).format("YYYY-MM-DD"),
    "day":day,
    "type":"pickup"
  }

  dispatch(GetPickupTimeSlotRequest(obj))
  setpickupTime("")
  
};

const PickupTimeHandle = (e) =>{
  setpickupTime(e.target.value)

  let obj = {
    "area":fetchReducer?.GetProfileResponse?.userData?.detailsData?.zone,
    "date":moment(ChooseDate).format("YYYY-MM-DD"),
    "day":selectday,
    "type":"drop",
    "pickupTime":e.target.value.split("||")[1]
  }

  dispatch(GetDeliveryTimeSlotRequest(obj))
}

const DateDeliveryHandle = (date)=>{
  setChoosedeliveryDate(date)
  let day = "";
  switch (date.getDay()) {
    case 0:
      day = "sunday";
      break;
    case 1:
      day = "monday";
      break;
    case 2:
      day = "tuesday";
      break;
    case 3:
      day = "wednesday";
      break;
    case 4:
      day = "thursday";
      break;
    case 5:
      day = "friday";
      break;
    case 6:
      day = "saturday";
  }

  let obj = {
    "area":fetchReducer?.GetProfileResponse?.userData?.detailsData?.zone,
    "date":moment(date).format("YYYY-MM-DD"),
    "day":day,
    // "type":"drop",
    // "pickupTime":pickupTime.split("||")[1]
  }

  dispatch(GetDeliveryTimeSlotRequest(obj))
}

const NextToConfirmOrder = () =>{
  if(pickupTime && deliveryTime !== ""){

    let rawpicktiime = pickupTime.replace("||", "-");
    let rawdeliverytiime = deliveryTime.replace("||", "-");

    let obj = {
        "slotBookingItemId": fetchReducer?.GetSlotListResponse?.data?.itemId ,
        "areaId":fetchReducer?.GetProfileResponse?.userData?.detailsData?.zone,
        "day":selectday,
        "bookingType":"single",
        "pickUpDate":moment(ChooseDate).format("DD-MM-YYYY"),
        "pickUpTime": rawpicktiime,  
        "deliveryDate":moment(ChoosedeliveryDate).format("DD-MM-YYYY"),
        "deliveryTime": rawdeliverytiime
    } 

    dispatch(SlotbookRequest({obj:obj, navigate:navigate}))
    console.log(obj)

  } else {
    toast.error('Delivery time & pickup time is mandatory');
  }
}

console.log(fetchReducer)




  return (
    <section className="dash-pills">
       <Loader visible={fetchReducer.isloading} />
    <div className="container">
      
        <div className="row">
            <div className="col-lg-8">
              <div className="pickup-slot">
                <div className='form-group'>
                    <h3>Pickup Slot</h3>
                    <label>Select date</label>
                    <DatePicker
                    selected={ChooseDate}
                    onChange={(date) => DateHandle(date)}
                    minDate={new Date()}
                    peekNextMonth
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select Date"
                    className="form-control input-style"
                    excludeDates={[new Date()]}
                  />
                </div>
                
                {fetchReducer?.GetTimeSlotResponse
                ?.status ?
                <div className='form-group'>
                  <label>Select Slot</label>
                  <ul className='slotList'>
                    {fetchReducer?.GetTimeSlotResponse?.data
                      ?.slotArr.map((slot, i)=>{
                        return  (
                          <li key={i} className={slot?.isDisable ? 'disabled': ''}>
                            <input type="radio" name='slot' value={slot?.fromTime +"||"+ slot?.toTime } onChange={PickupTimeHandle} checked={pickupTime == slot?.fromTime +"||"+ slot?.toTime} />
                            <label>{slot?.fromTime} - {slot?.toTime}</label>

                         </li>
                        )
                      })}
                    
                  </ul>
                </div>

                :
                <h5>{fetchReducer?.GetTimeSlotResponse?.message}</h5>
              }

              </div>
              {!pickupTime == '' ?
              <div className="pickup-slot">
                <div className='form-group'>
                    <h3>Delivery Slot</h3>
                    <label>Select date</label>
                    <DatePicker
                    selected={ChoosedeliveryDate}
                    onChange={(date) => DateDeliveryHandle(date)}
                    minDate={ChooseDate}
                    peekNextMonth
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select Date"
                    className="form-control input-style"
                    excludeDates={[new Date()]}
                  />
                </div>
                
                {fetchReducer?.GetdeliveryTimeSlotResponse
                ?.status ?
                <div className='form-group'>
                  <label>Select Slot</label>
                  <ul className='slotList'>
                    {fetchReducer?.GetdeliveryTimeSlotResponse?.data
                      ?.slotArr.map((slot, i)=>{
                        return  (
                          <li key={i} className={slot?.isDisable ? 'disabled': ''}>
                            <input type="radio" name='deliveryslot' value={slot?.fromTime +"||"+ slot?.toTime } onChange={(e)=>setdeliveryTime(e.target.value)} checked={deliveryTime == slot?.fromTime +"||"+ slot?.toTime} />
                            <label>{slot?.fromTime} - {slot?.toTime}</label>

                         </li>
                        )
                      })}
                    
                  </ul>
                </div>

                :
                <h5>{fetchReducer?.GetTimeSlotResponse?.message}</h5>
              }

              </div>
              :null
}
            </div>
            <div className="col-lg-4">
              <BookingOrderSummery/>
              <button className="dwnld-invc" onClick={NextToConfirmOrder}>Next</button>
            </div>
        </div>
    </div>
</section>
  )
}

export default BookSlot