import React, { useEffect, useState } from "react";
import {
  GetFaqRequest,
} from "../../redux/reducer/FetchDataReducer";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../utils/Loader";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Accordion from 'react-bootstrap/Accordion';

const Faq = () => {

  const fetchReducer = useSelector((state) => state.FetchDataReducer);

  const dispatch = useDispatch();
  let navigate = useNavigate();


useEffect(()=>{
    dispatch(GetFaqRequest())
    window.scrollTo(0,0)
},[])




  return (
    <section className="faq-sec">
<Loader visible={fetchReducer.isloading} />


            <div className="container">
   
        <h3>FAQ</h3>

        <Accordion defaultActiveKey="0">

      {fetchReducer?.GetFaqResponse?.faq&&fetchReducer?.GetFaqResponse?.faq.map((item, index)=>{
        return (
          <Accordion.Item eventKey={index}>
        <Accordion.Header> {item?.question}</Accordion.Header>
        <Accordion.Body>
        {item?.answer}
        </Accordion.Body>
      </Accordion.Item>
        )
      })}      

  
    </Accordion>

        </div>
      
        </section>
  )
}

export default Faq