import React, { useEffect, useState } from "react";
import {
    GetProcessRequest,
} from "../../redux/reducer/FetchDataReducer";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../utils/Loader";
import { NavLink, useNavigate } from "react-router-dom";

const Process = () => {
    const fetchReducer = useSelector((state) => state.FetchDataReducer);

    const dispatch = useDispatch();
    let navigate = useNavigate();
  
    console.log(fetchReducer.GetProcessResponse)
  
  useEffect(()=>{
      dispatch(GetProcessRequest())
  },[])



  return (
    <section className="custom-packg-sec">
         <Loader visible={fetchReducer.isloading} />
    <div className="container">
    <div className="pck-btn">
                <h3>Process</h3>
            </div>
        <div className="row">
            
{fetchReducer?.GetProcessResponse?.processes&&fetchReducer?.GetProcessResponse?.processes.map((item,i)=>{
    return (
        <div className="col-lg-4 col-md-6 col-sm-12">
        <div className="yutb-box">
            <div className="vdo-md">
            <video width="100%" height="240" controls>
                <source src={fetchReducer?.GetProcessResponse?.baseUrl + item?.image} type="video/mp4" />
            
                </video>
            </div>
            <h6>{item?.heading}</h6>
            <p>{item?.description}</p>
        </div>
    </div>
    )
})}

          

        


        </div>
    </div>
</section>
  )
}

export default Process