var url = window.location.origin + "/images/"


export const IMAGE = {
    loader: url + 'loader-icon.png',
    logo: url + 'logo.png',
    location: url + 'location.png',
    wallet : url + 'wallet.png',
    shoppingCart : url + 'shopping-cart.png',
    notificationIcon : url + 'notifications.png',
    language : url + 'metro-language.png',
    banner : url + 'banner-img.png',
    appleIcon : url + 'awesome-apple.png',
    androidIcon : url + 'simple-android.png',
    signupIcon : url + 'c1.png',
    cleaningIcon : url + 'c3.png',
    deliveryIcon : url + 'c2.png',
    sendIcon : url + 'send.png',
    authImage : url + 'LaundryService.png',
    aboutimg : url + 'about-us.png',
    login_img:url + 'login_img.png',
    pickup_img:url + 'pickup_img.png',
    cleaning:url + 'cleaning.png',
    Delivery:url + 'Delivery.png',
    succes_image:url + 'succes_image.png',
    upld:url + 'upld.png',
    
}