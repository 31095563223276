import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IMAGE } from '../../utils/Theme'
import { GetProfileRequest, UpdateProfileRequest } from '../../redux/reducer/FetchDataReducer'
import { AreaAction } from '../../redux/reducer/ProductReducer'
import Loader from '../../utils/Loader'
import moment from 'moment'
import { getApi, postApi } from '../../utils/ApiRequest'
import { toast } from 'react-toastify'

const Profile = () => {
 
  const fetchReducer = useSelector((state) => state.FetchDataReducer)
  var token = localStorage.getItem('access_token');
  const dispatch = useDispatch()

  const [fname, setfname] = useState('')
  const [phone, setphone] = useState('')
  const [gender, setgender] = useState('')
  const [dob, setdob] = useState(new Date())
  const [bname, setbname] = useState('')
  const [fno, setfno] = useState('')
  const [street, setstreet] = useState('')
  const [city, setcity] = useState('')
  const [area, setarea] = useState('')

const SubmitHandle  = async ()=>{
  let body = {
    "firstName": fname,
    "dob": dob,
    "gender": gender,
    "building_name": bname,
    "flat_no": fno,
    "street": street,
    "city": city,
    "zone":area,
    "phone":phone,
    "alt_phone":'',
}

let header = {
    Accept: 'application/json',
    contenttype: 'application/json',
    accesstoken:token
  };

  try {
    let response = await postApi('update-user-profile', body, header)
    if(response.data.status){

      toast.success(response.data?.message)
      GetApi()
    } else {

      toast.error(response.data?.message)
    }
  } catch(err){
    
  }

}

const ZoneHandling =  (e) =>{
    
    dispatch(AreaAction(e.target.value))
    setarea(e.target.value)
    localStorage.setItem('zone-area', e.target.value)
  }

  useEffect(()=> {
    GetApi()
 
  },[])

const GetApi =  async() =>{
    let header = {
        Accept: 'application/json',
        contenttype: 'application/json',
        accesstoken:token
      };
    
      try {
        let response = await getApi('user-profile', header)
        if(response.data.status){
            
            console.log("SDHSGDYSD", response?.data?.data)
            setbname(response?.data?.data.userData?.detailsData?.building_name)
            setfname(response?.data?.data.userData?.firstName)
            setarea(response?.data?.data.userData?.detailsData?.zone)
            setphone(response?.data?.data.userData?.phone)
            setgender(response?.data?.data.userData?.gender)
            setdob(  moment(response?.data?.data.userData?.dob).format('d-m-y'))
            setfno(response?.data?.data.userData?.detailsData?.flat_no)
            setstreet(response?.data?.data.userData?.detailsData?.street)
            setcity(response?.data?.data.userData?.detailsData?.city)
        
        } else {
    
          toast.error(response.data?.message)
        }
      } catch(err){
        
      }
}

  return (
    <section class="prfl-sec">
          <Loader visible={fetchReducer.isloading} />
    <div class="container">
        <h3>Profile</h3>
        <div class="row">
             <div class="col-lg-8">
            <div class="img-upload">
                <div class="img-upld-c">
                    <img src={fetchReducer?.GetProfileResponse?.userData?.profileImage} width="150" alt="profile"/>
                        {/* <input type="file" class="default-file-input" />
                         <span><img src={IMAGE.upld} alt=""/> </span> */}
                    </div>

                <div class="profile-details">
                    <h5>{fname}</h5>
                    <h6>{fetchReducer?.GetProfileResponse?.userPlan?.packageId?.packageName}</h6>
                    
                 
                </div>

            </div>
            </div>
          
            <div class="col-lg-6">

                <div class="form-box-1 mb-3">
                    <label for="exampleInputEmail1" class="form-label"><sup>*</sup> First Name</label>
                    <input type="text" class="form-control" placeholder="First Name"
                    value={fname}
                    onChange={(e)=>setfname(e.target.value)}
                    />
                </div>

             

                <div class="form-box-1 mb-3">
                    <label for="exampleInputEmail1" class="form-label"><sup>*</sup> Phone Number</label>
                    <input type="text" class="form-control" placeholder="XXXXXX"
                    maxLength={10}
                     value={phone}
                     onChange={(e)=>setphone(e.target.value)}
                     readOnly
                    />
                </div>


                <div class="form-box-1 mb-3">
                    <label for="exampleInputEmail1" class="form-label"><sup>*</sup> Gender</label>
                    <select className='form-control'
                        value={gender}
                        onChange={(e)=>setgender(e.target.value)}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                </div>

                {/* <div class="form-box-1 mb-3">
                    <label for="exampleInputEmail1" class="form-label"><sup>*</sup> Birthday</label>
                    <input type="date" id="datepicker" class="datepicker"
                    value={dob}
                    onChange={(e)=>setdob(e.target.value)}
                    />
                </div> */}



            </div>

            <div class="col-lg-6">

                {/* <div class="form-box-1 mb-3">
                    <label for="exampleInputEmail1" class="form-label"><sup>*</sup> Area</label>
                    <input type="text" class="form-control" placeholder="Sector V"/>
                </div> */}
                   <div class="form-box-1 mb-3">
                    <label for="exampleInputEmail1" class="form-label"><sup>*</sup> Select Area</label>
                    <select className="form-select" onChange={ZoneHandling}  value={area}>
                    <option selected value="zone">Choose Your Area</option>
                    {fetchReducer.ZoneListResponse?.data?.areas&&fetchReducer.ZoneListResponse?.data?.areas.map((item,index)=>{
                            return (
                              <option key={index} value={item?._id}>{item?.area}</option>
                            )
                          })}
                    </select>
                </div>

                <div class="form-box-1 mb-3">
                    <label for="exampleInputEmail1" class="form-label"><sup>*</sup> Building Name</label>
                    <input type="text" class="form-control" placeholder="Enter building name"
                        value={bname}
                        onChange={(e)=>setbname(e.target.value)}
                    />
                </div>

                <div class="form-box-1 mb-3">
                    <label for="exampleInputEmail1" class="form-label"><sup>*</sup> Flat No</label>
                    <input type="text" class="form-control" placeholder="Enter Flat no"
                     value={fno}
                     onChange={(e)=>setfno(e.target.value)}
                    />
                </div>

                <div class="form-box-1 mb-3">
                    <label for="exampleInputEmail1" class="form-label"><sup>*</sup> Street or Landmark</label>
                    <input type="email" class="form-control" placeholder="Enter Street or Landmark"
                      value={street}
                      onChange={(e)=>setstreet(e.target.value)}
                    />
                </div>

                <div class="form-box-1 mb-3">
                    <label for="exampleInputEmail1" class="form-label"><sup>*</sup> City</label>
                    <input type="text" class="form-control" placeholder="Enter City"
                      value={city}
                      onChange={(e)=>setcity(e.target.value)}
                    />
                </div>

                <button class="submt-btn-rvw" onClick={SubmitHandle}>UPDATE PROFILE</button>

       



            </div>
        </div>
    </div>
  
</section>
  )
}

export default Profile