import { all } from "redux-saga/effects";
import fetchDataSaga from './fetchDataSaga';

const combinedSaga = [

  ...fetchDataSaga,

];

 

export default function* RootSaga(){

  yield all(combinedSaga);

}