import React, { useEffect, useState } from "react";
import {
  GetOrderHistoryRequest,
} from "../../redux/reducer/FetchDataReducer";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../utils/Loader";

import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const OrderHistory = () => {
  const fetchReducer = useSelector((state) => state.FetchDataReducer);

  var Orderdata = fetchReducer?.GetOrderHistoryResponse?.data?.orders



  const dispatch = useDispatch();
  let navigate = useNavigate();

  console.log(fetchReducer)

useEffect(()=>{
    dispatch(GetOrderHistoryRequest())
},[])



  return (
    <>
      <Loader visible={fetchReducer.isloading} />

      <section className="faq-sec">
        <div className="container">
        <h3>My Orders</h3>
            {Orderdata&&Orderdata.length> 0 ?
            <div className="my-order-sec">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Order Date</th>
                      <th scope="col">Order ID</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Payment Mode</th>
                      <th scope="col">Status</th>
                      <th scope="col">View Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Orderdata&&Orderdata.map((item, i)=>{
                        return (
                            <tr key={i}>
                      <td>{item?.orderDate}</td>
                      <td># {item?.slotBookingId}</td>
                      <td>AED {item?.paidAmount?.$numberDecimal}</td>
                      <td>{item?.paymentStatus}</td>
                      <td>
                        <span>{item?.currentStatus}</span>
                      
                      </td>
                      <td>
                        <NavLink to={`/order-details/${item?._id}`}>
                        <i className="fa-regular fa-eye"></i>
                        </NavLink>
                       
                      </td>
                    </tr>
                        )
                    })}
                  
                  </tbody>
                </table>
              </div>
            </div>
            :
            <h5>No Order history</h5>
}
        </div>
      </section>
    </>
  );
};

export default OrderHistory;
