import React, { useEffect, useState } from 'react'

import { useElements, useStripe, PaymentElement} from '@stripe/react-stripe-js';
import { ConfirmPaymentRequest } from '../../redux/reducer/FetchDataReducer';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CheckoutForm = ({orderid}) => {
    const stripe = useStripe();
    const elements = useElements();        
    const [message, setMessage] = useState(null);
    const [isloading, setIsLoading] = useState(false);
    const dispatch = useDispatch()
    let navigate = useNavigate();

    useEffect(() => {
        if (!stripe) {
          return
        } 
  
        const clientSecret = new URLSearchParams(window.location.search).get(
          "payment_intent_client_secret"
        )
  
        if(!clientSecret){
          return
        }
  
        stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
            switch(paymentIntent.status){
              case "succeeded":
                setMessage("payment success")
  
                break;
                case "processing":
                  setMessage("Your payment is processing")
                  break;
  
                  case "requires_payment_method":
                    setMessage("Your payment was not successfully, please try again")
  
                    break;
  
                    default:
  
                    setMessage("something went wrong")
                    break;
            }
        })
  
  
      }, [stripe]);

      const HandleSubmit = async (e) => {

        e.preventDefault()
  
          if(!stripe || !elements){
            return
          }
  
          setIsLoading(true)
  
          const {error, paymentIntent } = await stripe.confirmPayment({
            elements,
            redirect: "if_required",
            // confirmParams:{
            //   return_url: `${window.location.origin}/#/payment/success/${orderid}`,
            // },
           
  
          })
  
  
          if(error){
            console.error(error);
          } else if (paymentIntent && paymentIntent.status === "succeeded") {
           
            handleSuccess();
          } else {
            setMessage("An unexpected error occured")
          }
  
          setIsLoading(false)
  
      }
  
      const PaymentElementOptions = {
        layout:"tabs",
      }

      const handleSuccess = () => {
        let obj = {
          'stripeOrderId': orderid,
        }
  
        dispatch(ConfirmPaymentRequest({obj: obj, navigate:navigate}))

      }


  return (
    <form id="payment-form" onSubmit={HandleSubmit}>
  
      <PaymentElement id="payment-element" options={PaymentElementOptions}  />

      <button disabled={isloading || !stripe || !elements} className='btn btn-primary mt-3'>Pay Now</button>
      {message && <p className='text-danger mt-2'>{message}</p>}
    </form>
  )
}

export default CheckoutForm